import { useNavigation } from "@remix-run/react";
import { FilterList, NavArrowDown, Xmark } from "iconoir-react";
import type React from "react";

import { MarketplaceOffersType } from "@goodcollect/shared/bookings";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@radix-ui/react-accordion";
import { Headline } from "components/UI/Headline.tsx";
import { Label } from "components/UI/Label.tsx";
import { Tag } from "components/UI/Tag.tsx";
import { ClintButton } from "components/shadcn-ui/button.tsx";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTrigger,
} from "components/shadcn-ui/sheet.tsx";
import {
  MarketplaceSidebarFilters,
  useMarketplaceSideFilters,
} from "hooks/useMarketplaceSideFilters.tsx";
import { cn } from "utils/utils.ts";
import { EquipmentTypeFilter } from "./SideFilters/EquipmentTypeFilter.tsx";
import { TreatmentTypeFilter } from "./SideFilters/TreatmentTypeFilter.tsx";
import { VolumeFilter } from "./SideFilters/VolumeFilter.tsx";
import { useState } from "react";

export type MarketplaceSideFilterOption = {
  gcId: number;
  name: string;
};

export const MarketplaceSideFilters = ({
  toggleVolume,
  toggleTreatmentType,
  treatmentTypeData,
  sideFilters,
  className,
  equipmentTypes,
  availableVolumes,
  toggleEquipmentType,
  availableEquipmentTypes,
  volumes,
}: {
  toggleTreatmentType: ReturnType<
    typeof useMarketplaceSideFilters
  >["toggleTreatmentType"];
  // toggleOfferType: (optionId: string) => void;
  toggleEquipmentType: ReturnType<
    typeof useMarketplaceSideFilters
  >["toggleEquipmentType"];
  toggleVolume: ReturnType<typeof useMarketplaceSideFilters>["toggleVolume"];
  treatmentTypeData: MarketplaceOffersType["treatmentTypeData"];
  sideFilters: MarketplaceSidebarFilters;
  hideFilters?: boolean;
  className?: string;
  equipmentTypes: MarketplaceOffersType["equipmentTypes"];
  availableEquipmentTypes: MarketplaceOffersType["availableEquipmentTypes"];
  availableVolumes: MarketplaceOffersType["availableVolumes"];
  volumes: MarketplaceOffersType["volumes"];
}) => {
  return (
    <MarketplaceSideFiltersWrapper className={className}>
      <SidebarFilters
        // mostExpensivePrice={mostExpensivePrice}
        // mostVoluminousOffer={mostVoluminousOffer}
        // setPrice={setPrice}
        sideFilters={sideFilters}
        toggleTreatmentType={toggleTreatmentType}
        treatmentTypeData={treatmentTypeData}
        // wasteTypes={wasteTypes}
        toggleEquipmentType={toggleEquipmentType}
        equipmentTypes={equipmentTypes}
        availableEquipmentTypes={availableEquipmentTypes}
        toggleVolume={toggleVolume}
        availableVolumes={availableVolumes}
        volumes={volumes}
      />
    </MarketplaceSideFiltersWrapper>
  );
};

const MarketplaceSideFiltersWrapper = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  const transition = useNavigation();
  const isLoading = transition.state !== "idle";
  return (
    <form
      id="side-filters"
      className={cn(
        "z-20 flex h-fit w-full max-w-full flex-col overflow-y-auto border-t bg-white  lg:max-w-[280px] lg:border lg:border-gray-200",
        className,
      )}
    >
      <Headline
        size={"h6"}
        as="div"
        className="hidden h-[88px] items-center justify-start border-gray-200 pl-5 lg:flex lg:border-b lg:pl-10"
      >
        Filtres
      </Headline>
      <div className={cn(`w-full lg:hidden ${isLoading ? "opacity-70" : ""}`)}>
        <Sheet>
          <SheetTrigger className={"w-full"}>
            <ClintButton
              as="div"
              variant="secondary"
              className="w-full border border-gray-200"
              // type="button"
              Icon={FilterList}
            >
              Filtrer
            </ClintButton>
          </SheetTrigger>

          <SheetContent
            hideCloseIcon
            side="bottom"
            className="flex max-h-full flex-col overflow-y-auto p-0"
            // className="h-auto w-full rounded-t-[8px] border-2 border-light-iron bg-white gap-5 lg:w-auto overflow-y-auto"
          >
            <div className="relative flex h-full flex-col">
              <SheetHeader className="flex flex-row items-center justify-between border-b border-gray-200 px-10 py-6">
                <Headline size="h4" variant={"secondary"}>
                  Filtres
                </Headline>
                <SheetClose>
                  <Tag
                    variant="Light"
                    type="Square"
                    className="aspect-square p-2"
                  >
                    <Xmark className="size-5" />
                    <span className="sr-only">Fermer</span>
                  </Tag>
                </SheetClose>
              </SheetHeader>
            </div>

            {children}
            <SheetFooter className="sticky bottom-0 mt-auto w-full border-t border-t-gray-200 bg-gray-100 px-10 py-6">
              <SheetClose className="w-full">
                <ClintButton
                  variant="primary"
                  as="Button"
                  type="button"
                  className="w-full"
                >
                  Valider
                </ClintButton>
              </SheetClose>
            </SheetFooter>
          </SheetContent>
        </Sheet>
      </div>
      {/* Desktop Wrapper */}
      <fieldset
        disabled={isLoading}
        className={`hidden w-full lg:block ${isLoading ? "opacity-70" : ""}`}
      >
        {children}
      </fieldset>
    </form>
  );
};

export const MarketplaceSideFilterWrapper = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  const [openDropdown, setOpenDropdown] = useState(true);
  return (
    <Accordion type="single" defaultValue="open" collapsible>
      <AccordionItem className="flex flex-col gap-4" value="open">
        <AccordionTrigger
          onClick={() => {
            setOpenDropdown(!openDropdown);
          }}
          className="flex items-center justify-between w-full"
        >
          <Label size="M" as="p">
            {title}
          </Label>
          <NavArrowDown
            className={`block size-6 ${openDropdown ? "rotate-180" : ""}`}
            aria-hidden
          />
        </AccordionTrigger>
        <AccordionContent>{children}</AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

const SidebarFilters = ({
  toggleTreatmentType,
  treatmentTypeData,
  toggleEquipmentType,
  sideFilters,
  equipmentTypes,
  availableEquipmentTypes,
  availableVolumes,
  toggleVolume,
  volumes,
}: {
  // mostExpensivePrice: number;
  // setPrice: ReturnType<typeof useMarketplaceSideFilters>["setPrice"];
  // mostVoluminousOffer: number;
  toggleVolume: ReturnType<typeof useMarketplaceSideFilters>["toggleVolume"];
  toggleTreatmentType: ReturnType<
    typeof useMarketplaceSideFilters
  >["toggleTreatmentType"];
  // toggleOfferType: ReturnType<typeof useMarketplaceSideFilters>['toggleOfferType'];
  toggleEquipmentType: ReturnType<
    typeof useMarketplaceSideFilters
  >["toggleEquipmentType"];
  // wasteTypes: Awaited<ReturnType<typeof getMarketplaceWasteTypes>>;
  treatmentTypeData: MarketplaceOffersType["treatmentTypeData"];
  sideFilters: MarketplaceSidebarFilters;
  equipmentTypes: MarketplaceOffersType["equipmentTypes"];
  availableEquipmentTypes: MarketplaceOffersType["availableEquipmentTypes"];
  availableVolumes: MarketplaceOffersType["availableVolumes"];
  volumes: MarketplaceOffersType["volumes"];
}) => {
  return (
    <div className="flex h-full flex-col gap-8 p-5 lg:px-10">
      <VolumeFilter
        toggleVolume={toggleVolume}
        availableVolumes={availableVolumes}
        selectedVolumes={sideFilters.selectedVolumes}
        volumes={volumes}
      />

      <EquipmentTypeFilter
        equipmentTypes={equipmentTypes}
        availableEquipmentTypes={availableEquipmentTypes}
        title="Types de bennes"
        toggleEquipmentType={toggleEquipmentType}
        selectedEquipmentTypes={sideFilters.selectedEquipmentTypes}
      />

      {/* <ul>
        <li>Ponctuel</li>
        <li>Récurrent</li>
        <li>Enlèvement immédiat</li>
      </ul> */}
      <TreatmentTypeFilter
        title="Type de valorisation"
        treatmentTypes={treatmentTypeData.treatmentTypes}
        selectedTreatmentTypes={sideFilters.selectedTreatmentTypes}
        disabledTreatmentTypes={treatmentTypeData.disabledTreatmentTypes}
        toggleTreatmentType={toggleTreatmentType}
        key={JSON.stringify(treatmentTypeData)}
      />

      {/* <OfferTypeFilter
        options={defaultOfferTypes}
        selectedOptions={sideFilters.selectedOptions}
        title="Types d'offre"
        toggleOption={toggleOfferType}
      />
      <MemoizedPriceFilter
        price={sideFilters.price}
        maxPrice={mostExpensivePrice}
        setPrice={setPrice}
      />*/}

      {/* <WasteTypeSideFilter name="waste" options={wasteTypes} />*/}
    </div>
  );
};
