import { Outlet, useLocation, useRouteLoaderData } from "@remix-run/react";
import Devtool from "components/Devtool.tsx";
import Footer from "components/Layout/Footer.tsx";
import { GeneralErrorBoundary } from "components/error-boundary.tsx";

import { LoaderFunctionArgs, json, redirect } from "@remix-run/node";
import { Navigation } from "components/Layout/Navigation.tsx";
import { PhoneWidget } from "components/Layout/Provider/PhoneWidget.tsx";
import { ContactUsSection } from "components/Sections/ContactUsSection";
import { generateCanonicalUrl } from "routes/redirects.server";
import {
  getBookableProvidersCount,
  getBookingPrice,
} from "server/public/transactional-page.server";
import { getUser } from "server/session.server";
import {
  getMarketplaceNewAccess,
  getMarketplaceOptionalOptions,
  getOfferItemDescription,
} from "server/utils.server";
import { useIsDevmode } from "utils/utils.ts";
import { v4 } from "uuid";

type LoaderResponse = {
  user: Awaited<ReturnType<typeof getUser>>;
  href: string;
  count: number;
  hasFetchedSimilarAddresses: boolean;
  offerItemDescription: ReturnType<typeof getOfferItemDescription> | null;
  uniqueId: string; // <= This id is used for websocket purposes
  offer: Awaited<ReturnType<typeof getBookingPrice>> | null;
} | null;

export const loader = async ({ context, request }: LoaderFunctionArgs) => {
  const currentUrlParams = new URL(request.url).searchParams;
  // check if url starts with /solutions/rechercher
  if (!request.url.includes("/solutions/rechercher")) {
    return json<LoaderResponse>(null);
  }

  const {
    shouldBeRedirectedToNearestAvailableStep,
    urlParams,
    searchUrl,
    options,
  } = getMarketplaceNewAccess({
    request,
  });

  if (shouldBeRedirectedToNearestAvailableStep || !options) {
    throw redirect(searchUrl);
  }

  if (
    !currentUrlParams.has("step") &&
    currentUrlParams.toString() !== urlParams.toString()
  ) {
    throw redirect(`?${urlParams.toString()}`);
  }
  const uniqueId = v4();

  // By default, server-side, we use the non-recurring prices and disable immediate pickups.
  options.isRecurring = "0";
  // options.immediatePickup = "0";
  // options.plans = "0";

  const [user] = await Promise.all([getUser({ request, context })]);

  const { startDate, endDate, page } = getMarketplaceOptionalOptions({
    request,
    context,
    allowOutdated: false,
    pickNextAvailableDateIfOutdated: true,
  });

  if (options.step === "1") {
    return json<LoaderResponse>({
      user,
      uniqueId,
      href: generateCanonicalUrl({
        request,
      }),
      count: 0,
      hasFetchedSimilarAddresses: false,
      offerItemDescription: null,
      offer: null,
    });
  }

  let count = 0;
  let hasFetchedSimilarAddresses = false;

  if (options.step === "2" || options.step === "3" || options.step === "4") {
    const { providerCount, hasFetchedSimilarAddresses: hasFetched } =
      await getBookableProvidersCount({
        request,
        params: {
          ...options,
          volume: "1",
          page,
          startDate,
          endDate,
          simulate: "0",
          isProfessional: options.isProfessional === "1" ? "1" : "0",
          stripeQuoteId: options.stripeQuoteId,
          userId: options.userId,
        },
        context,
      });
    count = providerCount;
    hasFetchedSimilarAddresses = hasFetched;
  }
  // Start of Step 2
  if (options.step === "2") {
    return json<LoaderResponse>({
      user,
      uniqueId,
      href: generateCanonicalUrl({ request }),
      count,
      hasFetchedSimilarAddresses,
      offerItemDescription: null,
      offer: null,
    });
  }
  if (options.step === "3" || options.step === "4") {
    const { startDate, endDate, haveOutdatedDatesBeenUpdated } =
      getMarketplaceOptionalOptions({
        request,
        context,
        allowOutdated: false,
        pickNextAvailableDateIfOutdated: true,
      });
    if (haveOutdatedDatesBeenUpdated) {
      urlParams.set("startDate", startDate);
      urlParams.set("endDate", endDate);
      throw redirect(`/solutions/rechercher?${urlParams.toString()}`);
    }
    // Options 3 and 4 are getting the selected offer information
    const offer = await getBookingPrice({
      params: {
        ...options,
        startDate,
        endDate,
      },
      context,
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      providerId: options.providerId!,
    });

    return json<LoaderResponse>({
      user,
      uniqueId,
      href: generateCanonicalUrl({ request }),
      count,
      hasFetchedSimilarAddresses,
      offerItemDescription: getOfferItemDescription({
        acceptedWastes: offer.offer.waste.acceptedWaste,
        dangerousWaste: offer.offer.waste.dangerousWaste,
        forbiddenWastes: offer.offer.waste.forbiddenWaste,
      }),
      offer,
    });
  }
  throw new Error("Invalid step");
};

const MainLayout = () => {
  const isDev = useIsDevmode();
  const location = useLocation();
  const disallowedPhoneUrls = [
    "/contact",
    "/dechetterie",
    "/rgpd",
    "/404",
    "/cgv",
    "/legal",
  ];
  const hidePhoneCta = disallowedPhoneUrls.some((disallowedUrl) =>
    location.pathname.includes(disallowedUrl),
  );

  const disallowedContactUsUrls = [
    "/contact",
    "/login",
    "/register-provider",
    "/solutions",
  ];
  const hideContactUsSection = disallowedContactUsUrls.some((disallowedUrl) =>
    location.pathname.includes(disallowedUrl),
  );

  const locationKey = useLocation().key;
  return (
    <div className="relative">
      {isDev ? <Devtool /> : null}

      <Navigation key={locationKey} />
      {hidePhoneCta ? null : <PhoneWidget />}
      <Outlet />
      {hideContactUsSection ? null : <ContactUsSection />}
      <Footer />
    </div>
  );
};

export const usePublicLayout = () => {
  const data = useRouteLoaderData<LoaderResponse>("routes/_public+/_layout");
  if (!data) return null;
  return data;
};
export default MainLayout;

export function ErrorBoundary() {
  const locationKey = useLocation().key;
  return (
    <div className="relative">
      <Navigation key={locationKey} />
      <GeneralErrorBoundary />
      <Footer />
    </div>
  );
}
