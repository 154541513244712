import { cn } from "utils/utils";

export function Instagram({ className }: { className?: string }) {
  return (
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("size-8 shrink-0", className)}
      fill="red"
      viewBox="0 0 20 20"
    >
      <path
        className="fill-current"
        d="M6.667 2.5A4.171 4.171 0 002.5 6.667v6.666A4.171 4.171 0 006.667 17.5h6.666a4.171 4.171 0 004.167-4.167V6.667A4.171 4.171 0 0013.333 2.5H6.667zm0 1.667h6.666c1.379 0 2.5 1.121 2.5 2.5v6.666c0 1.379-1.121 2.5-2.5 2.5H6.667a2.503 2.503 0 01-2.5-2.5V6.667c0-1.379 1.121-2.5 2.5-2.5zm7.5.833a.833.833 0 100 1.667.833.833 0 000-1.667zM10 5.833A4.171 4.171 0 005.833 10 4.171 4.171 0 0010 14.167 4.171 4.171 0 0014.167 10 4.171 4.171 0 0010 5.833zM10 7.5c1.378 0 2.5 1.122 2.5 2.5s-1.122 2.5-2.5 2.5A2.503 2.503 0 017.5 10c0-1.378 1.122-2.5 2.5-2.5z"
      ></path>
    </svg>
  );
}
