import { useEnv } from "app/root";
import { useOptionalUser } from "hooks/useUser";
import { useIsDevmode } from "utils/utils.ts";

export const GoogleAnalytics = ({
  GTM_ID,
  nonce,
}: {
  GTM_ID: string;
  nonce: string;
}) => {
  const isDev = useIsDevmode();
  const user = useOptionalUser();
  const { ENABLE_HOTJAR } = useEnv() || {};
  return (
    <>
      {/* We don't want to display  Google Analytics in Staging */}
      {isDev || user?.authOptions.isAdmin ? null : (
        <>
          {/* <!-- Google Tag Manager (Google Analytics) --> */}
          {/* <!-- Google Tag Manager --> */}
          <script
            nonce={nonce}
            // type="text/partytown"
            data-cookie-consent="tracking"
            dangerouslySetInnerHTML={{
              __html: `
					(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
					'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
				})(window,document,'script','dataLayer','${GTM_ID}');
				`,
            }}
          ></script>

          {/* <!-- Hotjar Tracking Code for https://goodcollect.co --> */}

          {ENABLE_HOTJAR ? (
            <script
              nonce={nonce}
              dangerouslySetInnerHTML={{
                __html: `
                (function(h,o,t,j,a,r){
                  h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
                  h._hjSettings={hjid:3564442,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
              `,
              }}
            ></script>
          ) : null}
          {/* <!-- End Google Tag Manager --> */}
          {/* <!-- end of Google Tag Manager (Google Analytics)--> */}

          <noscript>
            <iframe
              title="Google Tag Manager"
              src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
              height="0"
              width="0"
              style={{ display: "none", visibility: "hidden" }}
            ></iframe>
          </noscript>
        </>
      )}
    </>
  );
};
