import { MarketplaceSearchFiltersSchema } from "@goodcollect/shared";
import {
  MarketplaceOfferType,
  MarketplaceOffersType,
} from "@goodcollect/shared/bookings";
import { AppLoadContext } from "@remix-run/node";
import { useSearchParams } from "@remix-run/react";
import { useCallback, useMemo, useState } from "react";
import { z } from "zod";
export type MarketplaceSidebarFilters = {
  showHiddenOffers: boolean;
  selectedEquipmentTypes: MarketplaceOffersType["availableEquipmentTypes"];
  selectedVolumes: MarketplaceOffersType["availableVolumes"];

  selectedTreatmentTypes: Awaited<
    ReturnType<
      AppLoadContext["remixService"]["providerBookingQueriesService"]["getTreatmentTypes"]
    >
  >["treatmentTypes"];
  selectedBillingModes: z.infer<
    typeof MarketplaceSearchFiltersSchema
  >["billingMode"][];
};

export function sortOffersByPrice(allOffers: MarketplaceOfferType[]) {
  if (allOffers.length === 0) {
    return {
      bestTonOffer: null,
      bestPlanOffer: null,
      otherOffers: [],
      hiddenOffers: [],
    };
  }

  // Sort all offers by price
  const sortedOffers = allOffers.sort((a, b) => a.priceTTC - b.priceTTC);

  // Find the best plan offer and best ton offer
  const bestPlanOffer = sortedOffers.find((offer) => offer.isPlan) || null;
  const bestTonOffer = sortedOffers.find((offer) => !offer.isPlan) || null;

  // Filter out the best offers from the rest
  const remainingOffers = sortedOffers.filter(
    (offer) => offer !== bestPlanOffer && offer !== bestTonOffer,
  );

  // Split remaining offers into otherOffers and hiddenOffers
  const otherOffers = remainingOffers.slice(0, 4);
  const hiddenOffers = remainingOffers.slice(4);

  return {
    bestPlanOffer,
    bestTonOffer,
    otherOffers,
    hiddenOffers,
  };
}

export const useMarketplaceSideFilters = ({
  otherOffers,
  treatmentTypeData,
  availableEquipmentTypes,
  availableVolumes,
  hiddenOffers,
  bestPlanOffer,
  bestTonOffer,
}: Pick<
  MarketplaceOffersType,
  | "otherOffers"
  | "hiddenOffers"
  | "treatmentTypeData"
  | "availableEquipmentTypes"
  | "equipmentTypes"
  | "availableVolumes"
  | "volumes"
  | "bestPlanOffer"
  | "bestTonOffer"
>) => {
  const [searchParams] = useSearchParams();

  const [sideFilters, setSideFilters] = useState<MarketplaceSidebarFilters>({
    selectedTreatmentTypes: treatmentTypeData.selectedTreatmentTypes,
    selectedEquipmentTypes: availableEquipmentTypes,
    selectedVolumes: availableVolumes,
    showHiddenOffers: false,
    selectedBillingModes: ["per-ton", "recurring", "immediatePickup"],
  });

  const filterOffersByMaxPrice = useCallback(
    (offers: MarketplaceOffersType["otherOffers"]) => {
      if (sideFilters.selectedEquipmentTypes.length === 0) return [];
      if (sideFilters.selectedTreatmentTypes.length === 0) return [];
      if (sideFilters.selectedVolumes.length === 0) return [];

      const preferredVolumes = [10, 15]; // Preferred volumes
      const preferredType = "Benne (Ampliroll)"; // Preferred type

      return offers
        .filter((offer) => {
          const isTreatmentTypeSelected =
            sideFilters.selectedTreatmentTypes.some(
              (type) => type.gcId === offer.treatmentTypeId,
            );
          const isOfferFromSelectedEquipmentType =
            sideFilters.selectedEquipmentTypes.some(
              (set) => set.id === offer.equipment.type.id,
            );
          const isOfferVolumeSelected = sideFilters.selectedVolumes.includes(
            offer.equipment.volume,
          );

          return (
            isTreatmentTypeSelected &&
            isOfferFromSelectedEquipmentType &&
            isOfferVolumeSelected
          );
        })
        .sort((a, b) => {
          // 1. Sort by price (ascending)
          if (a.priceTTC !== b.priceTTC) {
            return a.priceTTC - b.priceTTC;
          }

          // 2. If prices are equal, prioritize "Benne (Ampliroll)" with 10m3 or 15m3
          const aIsPreferred =
            a.equipment.equipmentTypeName === preferredType &&
            preferredVolumes.includes(a.equipment.volume);
          const bIsPreferred =
            b.equipment.equipmentTypeName === preferredType &&
            preferredVolumes.includes(b.equipment.volume);

          if (aIsPreferred && !bIsPreferred) return -1; // a is preferred
          if (!aIsPreferred && bIsPreferred) return 1; // b is preferred

          // 3. If both are equal in preference (or neither is preferred), keep the original order
          return 0;
        });
    },
    [
      sideFilters.selectedTreatmentTypes,
      sideFilters.selectedEquipmentTypes,
      sideFilters.selectedVolumes,
    ],
  );

  // useEffect(() => {
  //   setSideFilters((filters) => ({
  //     ...filters,
  //     price: mostExpensivePrice,
  //   }));
  // }, [searchParams, mostExpensivePrice]);

  // const toggleOfferType = useCallback((optionId: string) => {
  //   setSideFilters((oldFilterData) => {
  //     // const { selectedOptions } = oldFilterData;
  //     // On a trouvé la valeur parmi les checkbox cochées, ça signifie que l'utilisateur souhaite décocher la checkbox
  //     const isSelected = selectedOptions.some((type) => type.id === optionId);

  //     return {
  //       ...oldFilterData,
  //       selectedOptions: isSelected
  //         ? // Déselection
  //         selectedOptions.filter((type) => type.id !== optionId)
  //         : [...selectedOptions, { id: optionId, name: optionId }],
  //     };
  //   });
  // }, []);

  const toggleEquipmentType = (equipmentTypeId: number) => {
    setSideFilters((oldFilterData) => {
      const { selectedEquipmentTypes } = oldFilterData;
      // On a trouvé la valeur parmi les checkbox cochées, ça signifie que l'utilisateur souhaite décocher la checkbox
      const isSelected = selectedEquipmentTypes.some(
        (type) => type.id === equipmentTypeId,
      );
      const selectedEquipmentType = availableEquipmentTypes.find(
        (et) => et.id === equipmentTypeId,
      );

      if (!selectedEquipmentType) {
        throw new Error("Equipment type id is required");
      }

      return {
        ...oldFilterData,
        selectedEquipmentTypes: isSelected
          ? // Déselection
            selectedEquipmentTypes.filter((type) => type.id !== equipmentTypeId)
          : [...selectedEquipmentTypes, selectedEquipmentType],
      };
    });
  };

  const toggleVolume = (volume: number) => {
    setSideFilters((oldFilterData) => {
      const { selectedVolumes } = oldFilterData;
      // On a trouvé la valeur parmi les checkbox cochées, ça signifie que l'utilisateur souhaite décocher la checkbox
      const isSelected = selectedVolumes.includes(volume);

      const selectedVolumesSet = new Set<number>(selectedVolumes);
      if (isSelected) {
        selectedVolumesSet.delete(volume);
      } else {
        selectedVolumesSet.add(volume);
      }

      return {
        ...oldFilterData,
        selectedVolumes: Array.from(selectedVolumesSet),
      };
    });
  };

  const toggleTreatmentType = useCallback(
    (treatmentTypeId: number | string) => {
      treatmentTypeId = Number(treatmentTypeId);
      const toggledTreatmentType = treatmentTypeData.treatmentTypes.find(
        (t) => t.gcId === treatmentTypeId,
      );
      if (!toggledTreatmentType) {
        throw new Error("Treatment type id is required");
      }

      setSideFilters((oldFilterData) => {
        const { selectedTreatmentTypes } = oldFilterData;
        // On a trouvé la valeur parmi les checkbox cochées, ça signifie que l'utilisateur souhaite décocher la checkbox
        const isSelected = selectedTreatmentTypes.some(
          (type) => type.gcId === treatmentTypeId,
        );

        return {
          ...oldFilterData,
          selectedTreatmentTypes: isSelected
            ? // Déselection
              selectedTreatmentTypes.filter(
                (type) => type.gcId !== treatmentTypeId,
              )
            : [...selectedTreatmentTypes, toggledTreatmentType],
        };
      });
    },
    [treatmentTypeData.treatmentTypes],
  );

  // const filteredOtherOffers = useMemo(() => {
  //   if (sideFilters.showHiddenOffers) {
  //     return filterOffersByMaxPrice(otherOffers.concat(hiddenOffers));
  //   }
  //   const filteredOtherOffers = filterOffersByMaxPrice(otherOffers);
  //   if (filteredOtherOffers.length === otherOffers.length) {
  //     return filteredOtherOffers;
  //   }
  //   const slicedValue = otherOffers.length - filteredOtherOffers.length;
  //   const filteredHiddenOffers = filterOffersByMaxPrice(hiddenOffers).slice(
  //     0,
  //     slicedValue,
  //   );

  //   return filteredOtherOffers.concat(filteredHiddenOffers);
  // }, [filterOffersByMaxPrice, otherOffers, sideFilters.showHiddenOffers]);

  // const filteredHiddenOffersCount = useMemo(() => {
  //   const filteredOtherOffers = filterOffersByMaxPrice(otherOffers);
  //   const slicedValue = otherOffers.length - filteredOtherOffers.length;
  //   return filterOffersByMaxPrice(hiddenOffers).length - slicedValue;
  // }, [filterOffersByMaxPrice, hiddenOffers, otherOffers]);

  // const filteredBestPlanOffer = useMemo(() => {
  //   if (bestPlanOffer) {
  //     const filteredOffers = filterOffersByMaxPrice([bestPlanOffer]);
  //     if (filteredOffers.length > 0) {
  //       return filteredOffers[0];
  //     }
  //   }
  //   return null;
  // }, [filterOffersByMaxPrice]);

  // const filteredBestTonOffer = useMemo(() => {
  //   if (bestTonOffer) {
  //     const filteredOffers = filterOffersByMaxPrice([bestTonOffer]);
  //     if (filteredOffers.length > 0) {
  //       return filteredOffers[0];
  //     }
  //   }
  //   return null;
  // }, [filterOffersByMaxPrice]);

  const {
    bestPlanOffer: filteredBestPlanOffer,
    bestTonOffer: filteredBestTonOffer,
    hiddenOffers: filteredHiddenOffers,
    otherOffers: filteredOtherOffers,
  } = useMemo(() => {
    const offersArray = [
      ...otherOffers.concat(hiddenOffers),
      bestPlanOffer,
      bestTonOffer,
    ].filter(Boolean);

    const filteredOffers = filterOffersByMaxPrice(offersArray);

    return {
      bestPlanOffer:
        filteredOffers.find((offer) => offer === bestPlanOffer) || null,
      bestTonOffer:
        filteredOffers.find((offer) => offer === bestTonOffer) || null,
      hiddenOffers: filteredOffers.filter((offer) =>
        hiddenOffers.includes(offer),
      ),
      otherOffers: filteredOffers.filter(
        (offer) => !hiddenOffers.includes(offer),
      ),
    };
  }, [
    filterOffersByMaxPrice,
    otherOffers,
    hiddenOffers,
    bestPlanOffer,
    bestTonOffer,
  ]);

  return {
    filteredOtherOffers: sideFilters.showHiddenOffers
      ? filteredOtherOffers.concat(filteredHiddenOffers)
      : filteredOtherOffers,
    filteredBestPlanOffer,
    filteredBestTonOffer,
    toggleTreatmentType,
    setSideFilters,
    sideFilters,
    searchParams,
    toggleEquipmentType,
    toggleVolume,
    filteredHiddenOffersCount: filteredHiddenOffers.length,
  };
};
