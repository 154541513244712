import { useEffect } from "react";
import type { ToastType } from "utils/toast.server.ts";
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "./toast.tsx";
import { useToast } from "./use-toast.ts";

export function Toaster({
  toastItem,
  hidden = false,
}: { toastItem: ToastType | null; hidden?: boolean }) {
  const { toasts, toast } = useToast();

  useEffect(() => {
    if (!toastItem) return;

    toast({
      title: toastItem?.title,
      description: toastItem.description,
      variant: toastItem.type,
    });
  }, [toastItem, toast]);

  if (hidden) return null;

  return (
    <ToastProvider>
      {toasts.map(
        ({ id, title, description, action, childrenOfToast, ...props }) => (
          <Toast key={id} {...props} className="my-2">
            <div className="grid gap-1">
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && (
                <ToastDescription>{description}</ToastDescription>
              )}
              {childrenOfToast ? childrenOfToast : null}
            </div>
            {action}
            <ToastClose />
          </Toast>
        ),
      )}
      <ToastViewport />
    </ToastProvider>
  );
}
