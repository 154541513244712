import { MarketplaceOffersType } from "@goodcollect/shared/bookings";
import { useMarketplaceSideFilters } from "hooks/useMarketplaceSideFilters.tsx";
import { MarketplaceSideFilterWrapper } from "../MarketplaceSideFilters.tsx";
import { SideFilterCheckbox } from "./SideFilterCheckbox.tsx";

export const TreatmentTypeFilter = ({
  toggleTreatmentType,
  title,
  disabledTreatmentTypes,
  selectedTreatmentTypes,
  treatmentTypes,
}: {
  title: string;
  treatmentTypes: MarketplaceOffersType["treatmentTypeData"]["treatmentTypes"];
  disabledTreatmentTypes: MarketplaceOffersType["treatmentTypeData"]["disabledTreatmentTypes"];
  selectedTreatmentTypes: MarketplaceOffersType["treatmentTypeData"]["selectedTreatmentTypes"];
  toggleTreatmentType: ReturnType<
    typeof useMarketplaceSideFilters
  >["toggleTreatmentType"];
}) => {
  return (
    <MarketplaceSideFilterWrapper title={title}>
      <div className="flex flex-col gap-4">
        {treatmentTypes.map((option) => {
          const isAvailable = !disabledTreatmentTypes.some(
            (aet) => aet.gcId === option.gcId,
          );
          return (
            <SideFilterCheckbox
              checked={
                isAvailable &&
                selectedTreatmentTypes.some((so) => so.gcId === option.gcId)
              }
              toggleOption={(valueId) => toggleTreatmentType(Number(valueId))}
              optionName={option.name}
              optionGcId={option.gcId}
              key={`treatment-type-${option.gcId}`}
              isDisabled={!isAvailable}
            />
          );
        })}
      </div>
    </MarketplaceSideFilterWrapper>
  );
};
