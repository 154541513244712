import { cn } from "utils/utils";

export function LinkedIn({ className }: { className?: string }) {
  return (
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("size-8 shrink-0", className)}
      fill="red"
      viewBox="0 0 20 20"
    >
      <path
        className="fill-current"
        d="M16.4 1.6H3.6a2 2 0 00-2 2v12.8a2 2 0 002 2h12.8a2 2 0 002-2V3.6a2 2 0 00-2-2zM6.8 8v7.6H4.4V8h2.4zM4.4 5.788c0-.56.48-.988 1.2-.988s1.172.428 1.2.988c0 .56-.448 1.012-1.2 1.012-.72 0-1.2-.452-1.2-1.012zM15.6 15.6h-2.4v-4c0-.8-.4-1.6-1.4-1.616h-.032c-.968 0-1.368.824-1.368 1.616v4H8V8h2.4v1.024S11.172 8 12.724 8c1.588 0 2.876 1.092 2.876 3.304V15.6z"
      ></path>
    </svg>
  );
}
