export const WebsocketIndicator = ({
  isSocketConnected,
}: {
  isSocketConnected: boolean;
}) => {
  return (
    <span
      style={{
        position: "fixed",
        top: "50%",
        left: "0",
        zIndex: 60,
        backgroundColor: "white",
      }}
      className="p-1 text-sm"
    >
      {isSocketConnected ? "🟢" : "🔴"}
    </span>
  );
};
