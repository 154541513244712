import { useLocation } from "@remix-run/react";

export const useLogoutUrl = () => {
  const location = useLocation();
  // let redirectTo = '/';

  // if (!location) {
  // 	return { redirectTo: '/' };
  // }

  const redirectTo = location.pathname + location.search;

  return { redirectTo };
};
