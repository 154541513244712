export const formatPhone = (phone: string) => {
  const hasCountryCode = phone.startsWith("+");

  if (hasCountryCode) {
    return phone.replace(
      /(\d{2})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/,
      "$1 $2 $3 $4 $5 $6",
    );
  }

  return phone.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4 $5");
};
