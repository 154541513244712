import { OptimizedImage } from "components/OptimizedImage";
import {
  RadioGroup,
  RadioGroupItem,
} from "components/shadcn-ui/radio-group.tsx";
import React from "react";
import { cn } from "utils/utils.ts";
import { Paragraph } from "./Paragraph";

export const SimpleSelect = ({
  options,
  defaultOption = "",
  name,
  disabled = false,
  onChange,
  className,
  itemClassName,
  imageClassName,
}: {
  name: string;
  defaultOption?: string;
  options: {
    id: string;
    name: string;
    value: string;
    title: string;
    subtitle: string;
    disabled?: boolean;
    imageUrl?: string;
  }[];
  disabled?: boolean;
  className?: string;
  itemClassName?: string;
  imageClassName?: string;
  onChange?: (value: string) => void;
}) => {
  const [selectedOption, setSelectedOption] = React.useState(defaultOption);
  return (
    <RadioGroup
      disabled={disabled}
      defaultValue={defaultOption}
      name={name}
      className={cn(`text-midnightblue flex flex-col items-start`, className)}
      onValueChange={(newValue) => {
        setSelectedOption(newValue);
        onChange?.(newValue);
      }}
    >
      <div className="mt-1 flex w-full flex-col flex-wrap gap-x-2 gap-y-2 lg:gap-x-3">
        {options.map((option) => (
          <label
            aria-describedby={option.id}
            className={cn(
              `relative flex cursor-pointer items-center gap-6 rounded-[8px] border-2 bg-white p-6 ${selectedOption === option.value && !option.disabled ? "border-primary" : option.disabled ? "cursor-not-allowed border-gray-300 opacity-85" : "hover:border-gray-600"}`,
              itemClassName,
            )}
            htmlFor={option.id}
            key={option.id}
          >
            <OptimizedImage
              alt={option.name}
              source={
                option.imageUrl ||
                "https://goodcollect-strapi.s3.eu-west-3.amazonaws.com/construction_work_2698790_640_cf19705e87.jpg"
              }
              // width={640}
              // height={427}
              className={cn("size-20 rounded-clint", imageClassName)}
              imageProps={{
                minHeight: "100%",
                objectFit: "cover",
              }}
              maxWidth={360}
              containerClassName="mx-0 shrink-0"
            />
            <div className="flex flex-col gap-1.5">
              <Paragraph size="lg" fontWeight={"bold"}>
                {option.title}
              </Paragraph>
              <Paragraph
                size="md"
                fontWeight={"regular"}
                className="text-gray-600"
              >
                {option.subtitle}
              </Paragraph>
            </div>

            <RadioGroupItem
              value={option.value}
              id={option.id}
              disabled={option.disabled}
              className={cn(
                "absolute right-0 top-0 -translate-x-full translate-y-full",
                {
                  "text-primary border-green-600 hover:border-green-600 hover:text-green-700 disabled:bg-gray-200 disabled:text-gray-500":
                    selectedOption === option.value,
                },
              )}
            />
          </label>
        ))}
      </div>
    </RadioGroup>
  );
};
