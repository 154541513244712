import React from "react";
import { ErrorList, ListOfErrors } from "../forms";

type HiddenInput = {
  name: string;
  value: string | undefined;
  errors?: ListOfErrors;
};
export const HiddenInputsArray = ({ inputs }: { inputs: HiddenInput[] }) => {
  return (
    <div className="flex flex-col gap-1">
      {inputs.map((input, index) => (
        <React.Fragment key={index}>
          <input type="hidden" name={input.name} value={input.value || ""} />
          <ErrorList errors={input.errors} />
        </React.Fragment>
      ))}
    </div>
  );
};
