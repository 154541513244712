import { cn } from "utils/utils";

export function Facebook({ className }: { className?: string }) {
  return (
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("size-8 shrink-0", className)}
      fill="red"
      viewBox="0 0 20 20"
    >
      <path
        className="fill-current"
        d="M10 1.667A8.333 8.333 0 001.667 10c0 4.496 3.562 8.15 8.017 8.317v-5.785H7.732v-2.27h1.952V8.59c0-1.937 1.184-2.993 2.913-2.993.582-.002 1.164.029 1.743.088V7.71h-1.19c-.942 0-1.125.445-1.125 1.101v1.446h2.25l-.293 2.271h-1.97v5.55c3.628-.902 6.321-4.172 6.321-8.08A8.333 8.333 0 0010 1.668z"
      ></path>
    </svg>
  );
}
