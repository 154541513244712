import { classed } from "@tw-classed/react";

export const ActionButton = classed(
  "button",
  "relative inline-flex font-bold leading-none items-center text-sm focus:outline-none",
  {
    variants: {
      color: {
        primary:
          "bg-teal text-white rounded-full hover:text-teal hover:bg-white focus:ring-2 focus:ring-teal focus:ring-offset-2",
        secondary: "bg-transparent text-teal",
        admin: "bg-transparent text-amber-600",
      },

      spacing: {
        large: "px-8 py-[10px]",
        light: "px-3 py-2",
        none: "px-3 py-0 leading-none",
      },
    },
    defaultVariants: {
      color: "secondary",
      spacing: "large",
    },
  },
);
