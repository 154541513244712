import { useEnv } from "app/root";
import { HomeArticle } from "components/Homepage/HomeArticle";
import { HomeSection } from "components/Homepage/HomeSection";
import { OptimizedImage } from "components/OptimizedImage";
import { Headline } from "components/UI/Headline";
import { Paragraph } from "components/UI/Paragraph";
import { ClintButton } from "components/shadcn-ui/button";
import { Mail, Phone } from "iconoir-react";
import greenVector from "public/images/green-vector.png";

export const ContactUsSection = () => {
  const { phoneLink, phoneDisplay, CONTACT_EMAIL } = useEnv() || {};
  return (
    <HomeSection color="white" horizontalPadding={"clint"}>
      <HomeArticle
        color="midnightblue"
        className="isolate relative overflow-hidden py-6 px-4 lg:px-24 lg:py-32 rounded-[12px]"
      >
        <div className="max-w-[65ch] flex flex-col gap-8 z-10">
          <div className="flex flex-col gap-6">
            <Headline size="h2" as="div" className="text-white">
              Nos experts vous accompagnent&nbsp;!
            </Headline>
            <Paragraph size="md" className="text-white">
              Vous avez une question ? Contactez-nous! GoodCollect vous
              accompagne dans votre démarche.
            </Paragraph>
          </div>
          <div className="flex gap-2 items-center flex-wrap w-fit">
            <ClintButton
              as="Link"
              iconPosition="left"
              linkProps={{
                to: phoneLink || "#",
                reloadDocument: true,
              }}
              variant="secondaryGray100"
              size="lg"
              Icon={Phone}
              className="w-fit grow"
            >
              {phoneDisplay}
            </ClintButton>
            <ClintButton
              as="Link"
              iconPosition="left"
              linkProps={{
                to: `mailto:${CONTACT_EMAIL}` || "#",
                reloadDocument: true,
              }}
              variant="secondaryGray100"
              size="lg"
              Icon={Mail}
              className="w-fit grow"
            >
              {CONTACT_EMAIL}
            </ClintButton>
          </div>
        </div>
        <OptimizedImage
          source={greenVector}
          alt="GoodCollect accompagnement par des expert de la decheterie"
          width={624}
          height={624}
          maxWidth={624}
          containerClassName="mx-0 absolute right-4 lg:right-8 top-0 translate-x-1/4 lg:translate-x-0 -translate-y-1/3 lg:-translate-y-24 scale-50 lg:scale-100"
        />
      </HomeArticle>
    </HomeSection>
  );
};
