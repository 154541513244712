import {
  getFieldsetProps,
  getFormProps,
  getInputProps,
  getTextareaProps,
  useForm,
} from "@conform-to/react";
import { getZodConstraint, parseWithZod } from "@conform-to/zod";
import {
  GenerateLeadQuoteSchema,
  LoginSchema,
  MarketplaceSearchFiltersSchema,
  UserRoles,
} from "@goodcollect/shared";
import {
  MarketplaceOfferType,
  MarketplaceOffersType,
  defaultPagination,
} from "@goodcollect/shared/bookings";
import { GenerateBookingSchema } from "@goodcollect/shared/customer";
import {
  ActionFunctionArgs,
  type LoaderFunctionArgs,
  type MetaFunction,
  json,
  redirect,
} from "@remix-run/node";
import {
  Form,
  Link,
  useActionData,
  useFetcher,
  useLocation,
  useNavigate,
  useNavigation,
  useSearchParams,
  useSubmit,
} from "@remix-run/react";
import { useEnv, useWasteTypes } from "app/root";
import {
  generateLeadQuote,
  getBookingPrice,
} from "app/server/public/transactional-page.server";
import { MarketplaceSideFilters } from "components/Bookings/MarketplaceSideFilters";
import { HomeArticle } from "components/Homepage/HomeArticle";
import { HomeSection } from "components/Homepage/HomeSection.tsx";
import { ArrowTriangle } from "components/Icons/ArrowTriangle";
import { Refresh } from "components/Icons/Refresh";
import { RefreshCircle } from "components/Icons/RefreshCircle";
import { Time } from "components/Icons/Time";
import { Truck } from "components/Icons/Truck";
import AppTooltip from "components/Interface/App/AppTooltip";
import { GeneralCombobox } from "components/Interface/Forms/Inputs/GeneralCombobox";
import { HiddenInputsArray } from "components/Interface/Forms/Inputs/HiddenInputsArray";
import {
  CheckboxField,
  ErrorList,
  Field,
  PhoneField,
  TextareaField,
} from "components/Interface/Forms/forms";
import { DatepickerInput } from "components/Interface/TransactionalPages/DatepickerInput";
import { ModalWrapper } from "components/Layout/Global/ModalWrapper";
import { TwoSidedFormInput } from "components/Layout/Global/TwoSidedFormInput";
import { MainPageWrapper } from "components/Layout/MainPageWrapper.tsx";
import { OptimizedImage } from "components/OptimizedImage";
import { Markdown } from "components/Sections/Markdown";
import { CardOffer, CheckedItem } from "components/UI/CardOffer";
import { DoubleSelect } from "components/UI/DoubleSelect";
import { Headline } from "components/UI/Headline";
import { Label } from "components/UI/Label";
import { MarketplacePanel } from "components/UI/MarketplacePanel";
import { Paragraph, paragraphVariants } from "components/UI/Paragraph";
import { SimpleSelect } from "components/UI/SimpleSelect";
import { SwitchBar } from "components/UI/SwitchBar";
import { Tag, TagsVariants } from "components/UI/Tag";
import { ClintButton } from "components/shadcn-ui/button";
import { SocketProvider, useSocket } from "hooks/socketsContext";
import { useIsSubmitting } from "hooks/useIsSubmitting";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useMarketplaceSearchBar } from "hooks/useMarketplaceSearchBar";
import { useMarketplaceSideFilters } from "hooks/useMarketplaceSideFilters";
import { useTailwindScreenSizes } from "hooks/useTailwindScreenSizes";
import { useOptionalUser } from "hooks/useUser";
import {
  ArrowLeft,
  Check,
  Clock,
  InfoCircle,
  Mail,
  Phone,
  RefreshDouble,
  Sparks,
  Trash,
  Xmark,
} from "iconoir-react";
import nProgress from "nprogress";
import { useCallback, useEffect, useMemo, useState } from "react";
import { generateCanonicalUrl } from "routes/redirects.server";
import { AddressCombobox } from "routes/resources+/get-address";
import {
  adminGetCompanyBySiret,
  verifyVatNumber,
} from "server/admin/admin-customers-management.server";
import { authenticateUser, checkUserExists, logout } from "server/auth.server";
import { createBooking } from "server/public/bookings.server";
import { getUser } from "server/session.server";
import {
  getMarketplaceNewAccess,
  getMarketplaceOptionalOptions,
  getOfferItemDescription,
} from "server/utils.server";
import "styles/nprogress.css";
import { formatDate } from "utils/dateformat";
import { formatPriceWithCurrency } from "utils/format-price";
import { generateMetatags } from "utils/generateMetatags.ts";
import { createToastHeaders } from "utils/toast.server";
import {
  BookingPriceParams,
  cn,
  formatSiret,
  removeStringSpaces,
} from "utils/utils";
import { z } from "zod";
import { usePublicLayout } from "../_layout";
import equipmentOfferImage from "./equipment-offer.png";
import equipmentImage from "./equipment.png";
import goodcollectVector from "./goodcollect-vector.png";

const dynamicLinks = ({
  data,
}: {
  data: { href: string };
}): ReturnType<MetaFunction> => [
  {
    rel: "canonical",
    href: data.href,
  },
];

export const handle = {
  // 👇This is a function that will be called client side with a 'href' argument.
  dynamicLinks,
};

// type LoaderResponse = {
//   user: Awaited<ReturnType<typeof getUser>>;
//   href: string;
//   count: number;
//   hasFetchedSimilarAddresses: boolean;
//   uniqueId: string;
//   offerItemDescription: ReturnType<typeof getOfferItemDescription> | null;
//   offer: Awaited<ReturnType<typeof getBookingPrice>> | null;
// };

// Loader moved in file _layout.tsx
export const loader = ({ request }: LoaderFunctionArgs) => {
  return json({
    href: generateCanonicalUrl({ request }),
  });
  // const currentUrlParams = new URL(request.url).searchParams;
  // const {
  //   shouldBeRedirectedToNearestAvailableStep,
  //   urlParams,
  //   searchUrl,
  //   options,
  // } = getMarketplaceNewAccess({
  //   request,
  // });

  // if (shouldBeRedirectedToNearestAvailableStep || !options) {
  //   throw redirect(searchUrl);
  // }

  // if (
  //   !currentUrlParams.has("step") &&
  //   currentUrlParams.toString() !== urlParams.toString()
  // ) {
  //   throw redirect(`?${urlParams.toString()}`);
  // }

  // // By default, server-side, we use the non-recurring prices and disable immediate pickups.
  // options.isRecurring = "0";
  // options.immediatePickup = "0";
  // // options.plans = "0";

  // const [user] = await Promise.all([getUser({ request, context })]);

  // const uniqueId = v4();
  // const { startDate, endDate, page } = getMarketplaceOptionalOptions({
  //   request,
  //   context,
  //   allowOutdated: false,
  //   pickNextAvailableDateIfOutdated: true,
  // });

  // if (options.step === "1") {
  //   return json<LoaderResponse>({
  //     user,
  //     href: generateCanonicalUrl({
  //       request,
  //     }),
  //     count: 0,
  //     hasFetchedSimilarAddresses: false,
  //     uniqueId,
  //     offerItemDescription: null,
  //     offer: null,
  //   });
  // }
  // // Start of Step 2
  // if (options.step === "2") {
  //   const { providerCount: count, hasFetchedSimilarAddresses } =
  //     await getBookableProvidersCount({
  //       request,
  //       params: {
  //         ...options,
  //         volume: "1",
  //         page,
  //         startDate,
  //         endDate,
  //         simulate: "0",
  //         isProfessional: options.isProfessional === "1" ? "1" : "0",
  //         stripeQuoteId: options.stripeQuoteId,
  //         userId: options.userId,
  //       },
  //       context,
  //     });

  //   return json<LoaderResponse>({
  //     user,
  //     href: generateCanonicalUrl({ request }),
  //     count,
  //     hasFetchedSimilarAddresses,
  //     uniqueId,
  //     offerItemDescription: null,
  //     offer: null,
  //   });
  // }
  // if (options.step === "3" || options.step === "4") {
  //   // Options 3 and 4 are getting the selected offer information
  //   const offer = await getBookingPrice({
  //     params: {
  //       ...options,
  //       startDate,
  //       endDate,
  //     },
  //     context,
  //     // biome-ignore lint/style/noNonNullAssertion: <explanation>
  //     providerId: options.providerId!,
  //   });

  //   return json<LoaderResponse>({
  //     user,
  //     href: generateCanonicalUrl({ request }),
  //     count: 0,
  //     hasFetchedSimilarAddresses: false,
  //     uniqueId,
  //     offerItemDescription: getOfferItemDescription(),
  //     offer,
  //   });
  // }
  // throw new Error("Invalid step");
};

export const action = async ({ request, context }: ActionFunctionArgs) => {
  const { shouldBeRedirectedToNearestAvailableStep, searchUrl, options } =
    getMarketplaceNewAccess({
      request,
    });

  if (shouldBeRedirectedToNearestAvailableStep || !options) {
    throw redirect(searchUrl);
  }

  const formData = await request.formData();

  // const { GOODCOLLECT_ENV } = serverEnv
  const user = await getUser({
    context,
    request,
  });
  const submission = await parseWithZod(formData, {
    async: true,
    schema: z
      .union([
        GenerateLeadQuoteSchema,
        GenerateBookingSchema,
        LoginSchema,
        z.object({
          action: z.literal("logout"),
        }),
      ])
      .superRefine(async (data, ctx) => {
        if (data.action === "logout") {
          return true;
        }
        if (data.action === "login") {
          const doesUserExist =
            await context.remixService.auth.checkIfUserExists({
              withPassword: true,
              email: data.email,
              password: data.password,
            });

          if (doesUserExist.error) {
            for (const message of doesUserExist.message) {
              ctx.addIssue({
                code: "custom",
                path: ["email"],
                message: message,
              });
              return false;
            }
            return false;
          }
          if (doesUserExist.role !== UserRoles.CUSTOMER) {
            ctx.addIssue({
              code: "custom",
              path: ["email"],
              message: "Seuls les comptes client peuvent réserver.",
            });
          }
          return true;
        }
        if (data.action === "booking") {
          if (!data.hasAcceptedTerms) {
            ctx.addIssue({
              code: "custom",
              path: ["hasAcceptedTerms"],
              message:
                "Vous devez accepter les conditions générales pour continuer.",
            });
            return false;
          }
          if (user && user.role !== UserRoles.CUSTOMER) {
            ctx.addIssue({
              code: "custom",
              message: "Seuls les comptes clients peuvent réserver.",
            });
            return false;
          }

          if (data.status === "logged-in" && !user) {
            ctx.addIssue({
              code: "custom",
              message:
                "Vous devez être connecté ou soumettre les informations d'un compte client pour continuer.",
            });
          }
          if (data.status === "logged-out" && data.customer) {
            const doesUserExist = await checkUserExists({
              email: data.customer.email,
              context,
            });

            if (!doesUserExist.isProspect && doesUserExist.error === false) {
              for (const message of doesUserExist.message) {
                ctx.addIssue({
                  code: "custom",
                  path: ["customer", "email"],
                  message: message,
                });
              }
            }

            if (
              doesUserExist.error === false &&
              doesUserExist.role !== UserRoles.CUSTOMER
            ) {
              ctx.addIssue({
                code: "custom",
                path: ["customer", "email"],
                message: "Seuls les comptes client peuvent réserver.",
              });
            }

            if (data.customer.customerType === "professionnal") {
              const siret = removeStringSpaces(data.customer.siret);
              const inseeFeedback = await adminGetCompanyBySiret({
                context,
                siret,
              });

              const { error, message } = inseeFeedback;

              if (error) {
                ctx.addIssue({
                  code: "custom",
                  path: ["customer.siret"],
                  message: message.join(", "),
                });
                return false;
              }

              if (data?.customer.tvaNumber) {
                const vatFeedback = await verifyVatNumber({
                  context,
                  vatNumber: data.customer.tvaNumber,
                });

                if (!vatFeedback.valid) {
                  ctx.addIssue({
                    code: "custom",
                    path: ["customer.tvaNumber"],
                    message: "Le numéro de TVA est invalide.",
                  });
                  return false;
                }
              }
            }
            // if (!data.customer?.captcha) {
            //   ctx.addIssue({
            //     code: "custom",
            //     path: ["captcha"],
            //     message: "Le captcha est requis.",
            //   });
            // }

            // // Don't test captcha in test environment. :)
            // if (GOODCOLLECT_ENV !== "test") {
            //   const { success } = await validateUserCaptcha({
            //     token: data.customer?.captcha ?? "",
            //   });

            //   if (!success) {
            //     ctx.addIssue({
            //       code: "custom",
            //       path: ["captcha"],
            //       message: "Le captcha vous a identifié en tant que robot.",
            //     });
            //   }
            // }
          }
        }

        if (data.action === "quote" && data.customerType === "professionnal") {
          const customerData = data;
          const siret = removeStringSpaces(customerData.siret);
          const inseeFeedback = await adminGetCompanyBySiret({
            context,
            siret,
          });

          const { error, message } = inseeFeedback;

          if (error) {
            ctx.addIssue({
              code: "custom",
              path: ["siret"],
              message: message.join(", "),
            });
          }

          if (customerData?.tvaNumber) {
            const vatFeedback = await verifyVatNumber({
              context,
              vatNumber: customerData.tvaNumber,
            });

            if (!vatFeedback.valid) {
              ctx.addIssue({
                code: "custom",
                path: ["tvaNumber"],
                message: "Le numéro de TVA est invalide.",
              });
            }
          }
        }
      })
      .transform((data) => {
        if (data.action === "login" || data.action === "logout")
          return { ...data };
        if (data.action === "quote") {
          return {
            ...data,
            ...(data.customerType === "professionnal" &&
              data.siret && {
                siret: removeStringSpaces(data.siret),
              }),
            email: data.email.toLowerCase(),
          };
        }
        if (data.action === "booking") {
          return {
            ...data,
            ...(data.status === "logged-out" && {
              customer: {
                ...data.customer,
                email: data.customer.email.toLowerCase(),
                ...(data.customer.customerType === "professionnal" &&
                  data.customer.siret && {
                    siret: removeStringSpaces(data.customer.siret),
                  }),
              },
            }),
          };
        }
        return data;
      }),
  });

  if (submission.status !== "success") {
    return json(
      {
        result: submission.reply(),
        error: false,
        message: "",
      } as const,
      {
        status: 400,
      },
    );
  }

  if (options.step !== "3" && options.step !== "4") {
    return json({
      result: submission.reply({
        formErrors: [
          "Une erreur s'est produite lors de la soumission du formulaire.",
        ],
      }),
    });
  }

  const submissionData = submission.value;

  switch (submissionData.action) {
    case "logout": {
      const url = new URL(request.url);
      return await logout({ redirectTo: url.toString(), context, request });
    }
    case "login": {
      return await authenticateUser({
        context,
        data: submissionData,
        request,
        customerAuth: true,
      });
    }
    case "quote": {
      const { startDate, endDate } = getMarketplaceOptionalOptions({
        request,
        context,
        defaultStartDate: submissionData.startDate,
        defaultEndDate: submissionData.endDate || "",
      });
      const feedback = await generateLeadQuote({
        params: {
          ...options,
          startDate,
          endDate,
        },
        userData: submissionData,
        context,
        request,
      });

      return json(
        {
          message: feedback.message,
          error: feedback.error,
          result: submission.reply({
            resetForm: true,
          }),
        } as const,
        {
          headers: await createToastHeaders({
            description: feedback.message.join(", "),
            type: feedback.error ? "error" : "success",
          }),
        },
      );
    }
    case "booking": {
      const { startDate, endDate, stripeQuoteId } =
        getMarketplaceOptionalOptions({
          request,
          context,
          defaultStartDate: submissionData.startDate,
          defaultEndDate: submissionData.endDate || "",
        });
      if (user?.role === UserRoles.CUSTOMER) {
        if (options.isProfessional === "0" && user.isProfessionalCustomer) {
          options.isProfessional = "1";
        }
        if (options.isProfessional === "1" && !user.isProfessionalCustomer) {
          options.isProfessional = "0";
        }
      }

      return await createBooking({
        request,
        additionalInfo: submissionData,
        params: {
          ...options,
          endDate,
          startDate,
          simulate: "0",
          stripeQuoteId,
        },
        context,
      });
    }
  }
};

export const meta: MetaFunction<typeof loader> = ({ location }) => {
  return generateMetatags({
    noIndex: true,
    pageUrl: location.pathname,
  });
};

export default function LocationDeBennesPage() {
  const { selectedFilters, setSelectedFilters } = useMarketplaceSearchBar({
    defaultService: 1,
  });
  const [searchParams] = useSearchParams();
  const stepParam: z.infer<typeof MarketplaceSearchFiltersSchema>["step"] =
    (searchParams.get("step") as "1" | "2" | "3" | "4") || "1";
  const [isSearchFiltersOpen, setIsSearchFiltersOpen] = useState(false);
  const user = useOptionalUser();

  const [quoteDetails, setQuoteDetails] = useState<{
    selectedOffer: Pick<
      MarketplaceOfferType,
      "equipmentPriceRuleId" | "providerId"
    > | null;
    showPanel: boolean;
  }>({
    showPanel: false,
    selectedOffer: null,
  });

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (stepParam !== "4") {
      window.localStorage.removeItem("book-details");
    }
  }, [stepParam]);

  return (
    <MainPageWrapper color="white" marginTop="none">
      <SocketProvider>
        <HomeSection
          color={"gray100"}
          verticalPadding="default"
          horizontalPadding="none"
          topPadding="none"
          key={stepParam}
          className="pt-0 lg:pt-0 xl:pt-0"
        >
          <HomeArticle
            className="gap-0"
            horizontalPadding={"none"}
            verticalPadding={"none"}
            maxWidth={"1440"}
          >
            <OfferQuotePanel
              setIsSearchFiltersOpen={setIsSearchFiltersOpen}
              title="Demander un devis"
              offer={quoteDetails.selectedOffer}
              open={quoteDetails.showPanel}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              closeMenu={() => {
                setQuoteDetails({
                  selectedOffer: null,
                  showPanel: false,
                });
              }}
            />
            {stepParam === "1" ? (
              <WasteSection
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
              />
            ) : null}
            {stepParam === "2" ? (
              <OffersListSection
                setIsSearchFiltersOpen={setIsSearchFiltersOpen}
                setQuoteDetails={setQuoteDetails}
              />
            ) : null}
            {stepParam === "3" ? (
              <OfferDetailSection setQuoteDetails={setQuoteDetails} />
            ) : null}
            {stepParam === "4" ? (
              <OfferBookingSection
                key={JSON.stringify(user)}
                setQuoteDetails={setQuoteDetails}
              />
            ) : null}
            <FiltersModal
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              isOpen={isSearchFiltersOpen}
              setIsOpen={setIsSearchFiltersOpen}
            />
          </HomeArticle>
        </HomeSection>
      </SocketProvider>
    </MainPageWrapper>
  );
}

const WasteSection = ({
  selectedFilters,
  setSelectedFilters,
}: {
  selectedFilters: BookingPriceParams;
  setSelectedFilters: React.Dispatch<React.SetStateAction<BookingPriceParams>>;
}) => {
  const transition = useNavigation();
  const isLoading = transition.state === "loading";
  const wasteTypes = useWasteTypes();
  const prioritizedIds = [5, 16, 15, 35, 9];

  const orderedWasteTypes: typeof wasteTypes = [];
  for (const id of prioritizedIds) {
    const foundWasteType = wasteTypes.find((type) => type.gcId === id);
    if (foundWasteType) {
      orderedWasteTypes.push(foundWasteType);
    }
  }
  for (const wasteType of wasteTypes.filter(
    (type) => !prioritizedIds.includes(type.gcId),
  )) {
    orderedWasteTypes.push(wasteType);
  }
  // wasteTypes, but add the ID 5 first, id 16 second, id 15 third, id 35 fourth and id 9 fifth. other id order is unchanged

  const [, setSearchParams] = useSearchParams();

  const MobileContinueButton = () => {
    return (
      <div className="fixed bottom-0 z-20 flex w-screen items-center justify-center border-t border-gray-300 bg-gray-100 p-4 lg:hidden">
        <ClintButton
          as="Button"
          iconPosition="right"
          variant="primary"
          size="lg"
          isLoading={isLoading}
          disabled={isLoading || !selectedFilters.waste}
          className="w-full"
          type="button"
          onClick={() => {
            setSearchParams((oldSearchParams) => {
              oldSearchParams.set("step", "2");
              oldSearchParams.set(
                "waste",
                selectedFilters.waste?.toString() || "",
              );

              return oldSearchParams;
            });
          }}
        >
          Continuer
        </ClintButton>
      </div>
    );
  };

  return (
    <div className="relative flex h-full w-full flex-col-reverse bg-gray-100 lg:min-h-screen lg:flex-row">
      <MobileContinueButton />
      <NeedHelpSideSection />
      <div className="flex w-full flex-col">
        <div className="flex h-[88px] w-full items-center justify-between gap-4 border border-l-0 border-gray-200 bg-white px-10">
          <div className="flex items-center gap-4">
            <Tag
              type="Square"
              variant="Light"
              className="flex aspect-square items-center justify-center lg:hidden"
            >
              <ArrowLeft className="size-4" aria-hidden="true" />
            </Tag>
            <Headline size={"h6"} as="div">
              Que souhaitez-vous jeter ?
            </Headline>
          </div>
          <ClintButton
            as="Button"
            iconPosition="right"
            variant="primary"
            size="lg"
            isLoading={isLoading}
            disabled={isLoading || !selectedFilters.waste}
            className="hidden w-full max-w-[190px] lg:flex"
            type="button"
            onClick={() => {
              setSearchParams(
                (oldSearchParams) => {
                  oldSearchParams.set("step", "2");
                  oldSearchParams.set(
                    "waste",
                    selectedFilters.waste?.toString() || "",
                  );

                  return oldSearchParams;
                },
                {
                  preventScrollReset: true,
                },
              );
            }}
          >
            Continuer
          </ClintButton>
        </div>
        <div className="w-full p-4">
          <div className="lg:bg-white lg:rounded-[8px] lg:p-6">
            <SimpleSelect
              className="max-w-[600px] mx-auto"
              onChange={(selectedWaste) => {
                setSelectedFilters((oldData) => ({
                  ...oldData,
                  waste: selectedWaste,
                }));
              }}
              name="waste"
              options={orderedWasteTypes.map((wt) => ({
                id: `waste-${wt.gcId}`,
                name: wt.name,
                value: wt.gcId.toString(),
                subtitle: wt.description,
                title: wt.name,
                imageUrl: wt.image?.fileUrl || "",
              }))}
              defaultOption={selectedFilters.waste?.toString() || ""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const OffersListSection = ({
  setIsSearchFiltersOpen,
  setQuoteDetails,
}: {
  setIsSearchFiltersOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setQuoteDetails: React.Dispatch<
    React.SetStateAction<{
      selectedOffer: Pick<
        MarketplaceOfferType,
        "equipmentPriceRuleId" | "providerId"
      > | null;
      showPanel: boolean;
    }>
  >;
}) => {
  const [, setSearchParams] = useSearchParams();
  const [searchParams] = useSearchParams();
  const { uniqueId, count, hasFetchedSimilarAddresses, user } =
    usePublicLayout() || {};
  // useLoaderData<typeof loader>();
  const { socket } = useSocket();
  const wasteTypes = useWasteTypes();
  const { isLargeScreen } = useTailwindScreenSizes({});

  const [offersData, setOffersData] = useState<MarketplaceOffersType>({
    otherOffers: [],
    bestPlanOffer: null,
    bestTonOffer: null,
    hiddenOffers: [],
    isStreaming: true,
    percentage: 0,
    // mostExpensivePrice: 700,
    // mostVoluminousOffer: 40,
    pagination: { ...defaultPagination, totalItems: count || 0 },
    treatmentTypeData: {
      disabledTreatmentTypes: [],
      treatmentTypes: [],
      selectedTreatmentTypes: [],
    },
    availableEquipmentTypes: [],
    availableVolumes: [],
    equipmentTypes: [],
    volumes: [7, 10, 15, 20, 25, 30],
  });

  const {
    filteredOtherOffers,
    setSideFilters,
    toggleVolume,
    sideFilters,
    toggleTreatmentType,
    toggleEquipmentType,
    filteredBestPlanOffer,
    filteredBestTonOffer,
    filteredHiddenOffersCount,
  } = useMarketplaceSideFilters({
    bestPlanOffer: offersData.bestPlanOffer,
    bestTonOffer: offersData.bestTonOffer,
    hiddenOffers: offersData.hiddenOffers,
    otherOffers: offersData.otherOffers,
    treatmentTypeData: offersData.treatmentTypeData,
    availableEquipmentTypes: offersData.availableEquipmentTypes,
    availableVolumes: offersData.availableVolumes,
    equipmentTypes: offersData.equipmentTypes,
    volumes: offersData.volumes,
  });

  const fetchOffers = useCallback(() => {
    if (!socket) return;
    const searchParamsAsObject = Object.fromEntries(searchParams);
    socket.emit("offers", {
      uniqueId,
      ...searchParamsAsObject,
      ...(user && { userId: user?.authOptions?.userId }),
      hasFetchedSimilarAddresses,
      count,
    });
  }, [count, hasFetchedSimilarAddresses, searchParams, socket, uniqueId, user]);

  useEffect(() => {
    if (!socket) return;

    fetchOffers();

    socket.on("offers-update", (offersUpdate: MarketplaceOffersType) => {
      const updateOffers = () => {
        setOffersData(offersUpdate);
        const { treatmentTypeData } = offersUpdate;
        setSideFilters((oldFilters) => ({
          ...oldFilters,
          // price: mostExpensivePrice,
          // volume: mostVoluminousOffer,
          selectedTreatmentTypes: treatmentTypeData.selectedTreatmentTypes,
          selectedEquipmentTypes: offersUpdate.availableEquipmentTypes,
          selectedVolumes: offersUpdate.availableVolumes,
        }));
      };

      // Add a 300ms delay to avoid flickering. If more than one update appears, we will only display the last one
      const updateOffersTimeout = setTimeout(updateOffers, 120);
      return () => clearTimeout(updateOffersTimeout);
    });
  }, [socket, fetchOffers, setSideFilters]);

  const GOODCOLLECT_ENV = useEnv()?.GOODCOLLECT_ENV || "production";
  const [isNprogressConfigured, setIsNprogressConfigured] = useState(false);
  useEffect(() => {
    if (typeof window === "undefined") return;
    if (GOODCOLLECT_ENV === "test") return;
    // Start showing loading bar.
    nProgress.configure({
      speed: 500,
      showSpinner: false,

      // spinnerSelector: '#nprogress-bar',
      parent: "#nprogress-bar",
    });
    nProgress.start();
    setIsNprogressConfigured(true);
  }, []);

  useEffect(() => {
    if (!isNprogressConfigured) return;
    if (typeof window === "undefined") return;
    if (GOODCOLLECT_ENV === "test") return;

    if (offersData.isStreaming === false) {
      nProgress.done();
      return;
    }

    nProgress.set(offersData.percentage / 100);
  }, [offersData, GOODCOLLECT_ENV, isNprogressConfigured]);

  const onBookClick = (offer: MarketplaceOfferType) => {
    setSearchParams((oldParams) => {
      oldParams.set("step", "3");
      oldParams.set("providerId", offer.providerId);
      oldParams.set(
        "equipmentPriceRuleId",
        offer.equipmentPriceRuleId.toString(),
      );
      oldParams.set("waste", `${offer.waste.gcId}`);
      oldParams.set("plans", offer.isPlan ? "1" : "0");
      return oldParams;
    });
  };

  const isProfessional = searchParams.get("isProfessional") === "1";
  // type the openOfferDetails state , open:boolean and type : "ton" | "plan"
  const [openOfferDetails, setOpenOfferDetails] = useState<{
    open: boolean;
    type: "ton" | "plan";
  }>({
    open: false,
    type: "ton",
  });

  const hasOffers =
    offersData.isStreaming ||
    Boolean(filteredBestTonOffer) ||
    Boolean(filteredBestPlanOffer) ||
    filteredOtherOffers.length > 0;
  const navigate = useNavigate();

  const bestOfferTonDisplay = useMemo(() => {
    if (filteredBestTonOffer) {
      return filteredBestTonOffer;
    }
    // Si filteredBestTonOffer est null, trouvez la meilleure offre parmi filteredOtherOffers
    return filteredOtherOffers.filter((offer) => !offer.isPlan).length > 0
      ? filteredOtherOffers
          .filter((offer) => !offer.isPlan)
          .reduce((best, current) =>
            current.priceTTC < best.priceTTC ? current : best,
          )
      : null;
  }, [filteredBestTonOffer, filteredOtherOffers]);
  const bestOfferPlanDisplay = useMemo(() => {
    if (filteredBestPlanOffer) {
      return filteredBestPlanOffer;
    }
    // Si filteredBestTonOffer est null, trouvez la meilleure offre parmi filteredOtherOffers qui est un plan
    if (filteredOtherOffers.filter((offer) => offer.isPlan).length > 0) {
      return filteredOtherOffers
        .filter((offer) => offer.isPlan)
        .reduce((best, current) =>
          current.priceTTC < best.priceTTC ? current : best,
        );
    }
    return null;
  }, [filteredBestPlanOffer, filteredOtherOffers]);
  useEffect(() => {
    if (!sideFilters.showHiddenOffers && !hasOffers) {
      setSideFilters((oldFilters) => ({
        ...oldFilters,
        showHiddenOffers: true,
      }));
    }

    if (sideFilters.showHiddenOffers && filteredOtherOffers.length > 5) {
      setSideFilters((oldFilters) => ({
        ...oldFilters,
        showHiddenOffers: false,
      }));
    }
  }, [bestOfferPlanDisplay, bestOfferTonDisplay, hasOffers]);

  return (
    <div className="relative mx-auto flex h-full w-full max-w-[1440px] flex-col bg-gray-100 lg:min-h-screen lg:flex-row">
      {isProfessional || user?.authOptions.isAdmin ? (
        <MarketplaceSideFilters
          className="hidden lg:flex"
          toggleVolume={toggleVolume}
          toggleTreatmentType={toggleTreatmentType}
          treatmentTypeData={offersData.treatmentTypeData}
          sideFilters={sideFilters}
          equipmentTypes={offersData.equipmentTypes}
          availableVolumes={offersData.availableVolumes}
          volumes={offersData.volumes}
          toggleEquipmentType={toggleEquipmentType}
          availableEquipmentTypes={offersData.availableEquipmentTypes}
        />
      ) : isLargeScreen && hasOffers ? (
        <NeedHelpSideSection />
      ) : null}
      <OfferInformationPanel
        closeMenu={() => {
          setOpenOfferDetails({ open: false, type: "ton" });
        }}
        open={openOfferDetails.open}
        type={openOfferDetails.type}
      />
      <div id="nprogress-bar" className="flex w-full flex-col gap-4 pb-8">
        <div className="flex flex-col">
          {hasOffers ? (
            <div className="z-10 flex h-[88px] w-full items-center gap-6 overflow-x-auto border border-l-0 border-gray-200 bg-white px-10">
              <ClintButton
                as="a"
                size={"medium"}
                variant={"secondary"}
                className="w-fit rounded-[8px] bg-green-600 px-5 py-2 text-white"
                labelClassName="text-white text-base"
                hideIcon
                anchorProps={{
                  href: isProfessional ? "#per-ton" : "#recommended",
                }}
              >
                {isProfessional
                  ? "Prix à la tonne"
                  : "Recommandation GoodCollect"}
              </ClintButton>
              <ClintButton
                as="a"
                className="w-fit rounded-[8px] border-none px-5 py-2"
                size={"medium"}
                hideIcon
                variant={"secondary"}
                labelClassName="text-base"
                anchorProps={{
                  href: "#per-plan",
                }}
              >
                {isProfessional ? "Prix forfaitaire" : "Offres supplémentaires"}
              </ClintButton>
              {isProfessional && filteredOtherOffers.length > 0 ? (
                <ClintButton
                  as="a"
                  className="w-fit rounded-[8px] border-none px-5 py-2"
                  size={"medium"}
                  hideIcon
                  variant={"secondary"}
                  labelClassName="text-base"
                  anchorProps={{
                    href: "#offers",
                  }}
                >
                  Offres supplémentaires
                </ClintButton>
              ) : null}
            </div>
          ) : null}

          {isProfessional ? (
            <MarketplaceSideFilters
              className="pt-0 p-5 flex lg:hidden"
              toggleVolume={toggleVolume}
              toggleTreatmentType={toggleTreatmentType}
              treatmentTypeData={offersData.treatmentTypeData}
              sideFilters={sideFilters}
              equipmentTypes={offersData.equipmentTypes}
              availableVolumes={offersData.availableVolumes}
              volumes={offersData.volumes}
              toggleEquipmentType={toggleEquipmentType}
              availableEquipmentTypes={offersData.availableEquipmentTypes}
            />
          ) : null}
        </div>

        <div className="flex flex-col gap-6 lg:px-6">
          <div
            id={isProfessional ? "per-ton" : "recommended"}
            className="flex w-full scroll-mt-60 flex-col gap-6 rounded-[8px] bg-white p-4"
          >
            {hasOffers ? (
              <div className="flex flex-col gap-1">
                <Headline
                  size={"h4"}
                  as="div"
                  variant={"secondary"}
                  className="flex items-center gap-2 flex-wrap whitespace-nowrap"
                >
                  <span>
                    {isProfessional
                      ? "Prix à la tonne"
                      : "GoodCollect vous recommande"}
                  </span>

                  {isProfessional ? (
                    <>
                      <Tag
                        type="Square"
                        variant="Light"
                        className="flex h-fit items-center justify-center px-2 py-1"
                      >
                        Recommandé
                      </Tag>
                      <ClintButton
                        variant={"tertiary"}
                        as="Button"
                        className="text-secondary px-0 ml-auto border-none underline"
                        onClick={() => {
                          setOpenOfferDetails({ open: true, type: "ton" });
                        }}
                      >
                        Comment ça fonctionne ?
                      </ClintButton>
                    </>
                  ) : null}
                </Headline>
                <Paragraph size="md" variant={"secondary"}>
                  Basé sur notre expérience, nous vous recommandons cette offre
                  pour une gestion efficace et écologique de vos déchets
                </Paragraph>
              </div>
            ) : (
              <div className="flex flex-col gap-1">
                <Headline
                  size={"h4"}
                  as="div"
                  variant={"secondary"}
                  className="flex items-center gap-2"
                >
                  <span>Aucune offre réservable en ligne actuellement</span>

                  {isProfessional ? (
                    <ClintButton
                      variant={"tertiary"}
                      as="Button"
                      className="ml-auto"
                      onClick={() => {
                        setIsSearchFiltersOpen(true);
                      }}
                    >
                      Modifier mon adresse
                    </ClintButton>
                  ) : null}
                </Headline>
                <Paragraph size="md" variant={"secondary"}>
                  Nous n'avons pas trouvé d'offres correspondant à vos critères
                  de recherche : modifiez-les ou contactez-nous !
                </Paragraph>
              </div>
            )}
            {bestOfferTonDisplay ? (
              <CardOffer
                type="offer"
                equipmentWeight={bestOfferTonDisplay.equipment.equipmentWeight}
                priceHT={bestOfferTonDisplay.priceHT}
                isProfessional={isProfessional}
                priceTTC={bestOfferTonDisplay.priceTTC}
                key={`${bestOfferTonDisplay.isPlan ? "plan" : "ton"}-${bestOfferTonDisplay.equipmentPriceRuleId}`}
                equipmentName={`${bestOfferTonDisplay.equipment.type.name} - ${bestOfferTonDisplay.equipment.volume}m3`}
                height={bestOfferTonDisplay.equipment.height}
                length={bestOfferTonDisplay.equipment.length}
                width={bestOfferTonDisplay.equipment.width}
                imageUrl={
                  bestOfferTonDisplay.equipment.imageUrl || equipmentImage
                }
                onBookClick={() =>
                  bestOfferTonDisplay && onBookClick(bestOfferTonDisplay)
                }
                onQuoteClick={() => {
                  if (!bestOfferTonDisplay) return;
                  setQuoteDetails({
                    selectedOffer: {
                      equipmentPriceRuleId:
                        bestOfferTonDisplay?.equipmentPriceRuleId,
                      providerId: bestOfferTonDisplay?.providerId,
                    },
                    showPanel: true,
                  });
                }}
              />
            ) : null}

            {!hasOffers ? (
              <CardOffer
                type="no-offer"
                isProfessional={isProfessional}
                equipmentName={``}
                height={80}
                length={560}
                width={255}
                imageUrl={equipmentImage}
                onQuoteClick={() => {
                  const startDate = searchParams.get("startDate") ?? "";
                  const endDate = searchParams.get("endDate") ?? "";
                  const volume = searchParams.get("volume") ?? "";
                  const address = searchParams.get("address") ?? "";
                  const waste = searchParams.get("waste") ?? "";
                  const wasteType = wasteTypes.find(
                    (w) => w.gcId.toString() === waste,
                  );
                  if (!wasteType) throw new Error("did not find waste type");

                  const wasteName = wasteType.name;

                  const dateDescription = `${
                    startDate
                      ? `à partir du ${startDate} ${endDate ? `au ${endDate}` : ""}`
                      : ""
                  }`;
                  const defaultDescription =
                    `Bonjour, je recherche une solution pour ${volume}m3 de ${wasteName} au ${address} ${dateDescription}.`.trim();
                  const addDescriptionParam = (description: string) => {
                    searchParams.set("description", description);
                    return searchParams;
                  };

                  const redirectUrl = `/contact?${addDescriptionParam(
                    defaultDescription +
                      ` Je souhaite recevoir un devis par mail.`,
                  ).toString()}`;

                  navigate(redirectUrl);
                }}
              />
            ) : null}
          </div>
          {isProfessional && bestOfferPlanDisplay ? (
            <>
              <div className="my-2 h-[1px] w-full bg-gray-200"></div>
              <div
                id={"per-plan"}
                className="flex w-full scroll-mt-60 flex-col gap-6 rounded-[8px] bg-white p-6"
              >
                <div className="flex flex-col gap-1">
                  <Headline
                    size={"h4"}
                    as="div"
                    variant={"secondary"}
                    className="flex items-center gap-2 whitespace-nowrap flex-wrap"
                  >
                    Prix forfaitaire
                    <ClintButton
                      variant={"tertiary"}
                      as="Button"
                      className="text-secondary ml-auto px-0 border-none underline"
                      onClick={() => {
                        setOpenOfferDetails({ open: true, type: "plan" });
                      }}
                    >
                      Comment ça fonctionne ?
                    </ClintButton>
                  </Headline>
                  <Paragraph size="md" variant={"secondary"}>
                    Correspond à une offre comprenant le transport, la location
                    et le traitement des déchets.
                  </Paragraph>
                </div>
                {bestOfferPlanDisplay ? (
                  <CardOffer
                    type="offer"
                    equipmentWeight={
                      bestOfferPlanDisplay.equipment.equipmentWeight
                    }
                    priceHT={bestOfferPlanDisplay.priceHT}
                    isProfessional={isProfessional}
                    priceTTC={bestOfferPlanDisplay.priceTTC}
                    key={`${bestOfferPlanDisplay.isPlan ? "plan" : "ton"}-${bestOfferPlanDisplay.equipmentPriceRuleId}`}
                    equipmentName={`${bestOfferPlanDisplay.equipment.type.name} - ${bestOfferPlanDisplay.equipment.volume}m3`}
                    height={bestOfferPlanDisplay.equipment.height}
                    length={bestOfferPlanDisplay.equipment.length}
                    width={bestOfferPlanDisplay.equipment.width}
                    imageUrl={
                      bestOfferPlanDisplay.equipment.imageUrl || equipmentImage
                    }
                    onBookClick={() =>
                      bestOfferPlanDisplay && onBookClick(bestOfferPlanDisplay)
                    }
                    onQuoteClick={() => {
                      if (!bestOfferPlanDisplay) return;
                      setQuoteDetails({
                        selectedOffer: {
                          equipmentPriceRuleId:
                            bestOfferPlanDisplay?.equipmentPriceRuleId,
                          providerId: bestOfferPlanDisplay?.providerId,
                        },
                        showPanel: true,
                      });
                    }}
                  />
                ) : null}
              </div>
            </>
          ) : null}
          {filteredOtherOffers.length > 0 ? (
            <>
              <div className="my-2 h-[1px] w-full bg-gray-200"></div>
              <div
                id="offers"
                className="flex w-full scroll-mt-60 flex-col gap-6 rounded-[8px] bg-white p-6"
              >
                <div className="flex flex-col gap-1">
                  <Headline size={"h4"} as="div" variant={"secondary"}>
                    Offres supplémentaires
                  </Headline>
                </div>
                <div className="flex flex-col gap-6">
                  {filteredOtherOffers.map((offer) => (
                    <CardOffer
                      type="offer"
                      isPlan={offer.isPlan}
                      priceHT={offer.priceHT}
                      equipmentWeight={offer.equipment.equipmentWeight}
                      isProfessional={isProfessional}
                      priceTTC={offer.priceTTC}
                      key={`${offer.isPlan ? "plan" : "ton"}-${offer.equipmentPriceRuleId}`}
                      equipmentName={`${offer.equipment.type.name} - ${offer.equipment.volume}m3`}
                      imageUrl={offer.equipment.imageUrl || equipmentImage}
                      height={offer.equipment.height}
                      length={offer.equipment.length}
                      width={offer.equipment.width}
                      onBookClick={() => onBookClick(offer)}
                      onQuoteClick={() => {
                        setQuoteDetails({
                          selectedOffer: {
                            equipmentPriceRuleId: offer.equipmentPriceRuleId,
                            providerId: offer.providerId,
                          },
                          showPanel: true,
                        });
                      }}
                    />
                  ))}
                </div>
                {filteredHiddenOffersCount > 0 ? (
                  <ClintButton
                    as="Button"
                    variant="tertiary"
                    size="medium"
                    type="button"
                    className="mr-auto"
                    onClick={() => {
                      setSideFilters((oldFilters) => ({
                        ...oldFilters,
                        showHiddenOffers: !oldFilters.showHiddenOffers,
                      }));
                    }}
                  >
                    {!sideFilters.showHiddenOffers
                      ? `Afficher plus (${filteredHiddenOffersCount})`
                      : "Cacher les offres supplémentaires"}
                  </ClintButton>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      </div>
      {!isProfessional && !isLargeScreen && hasOffers ? (
        <NeedHelpSideSection />
      ) : null}
    </div>
  );
};

const OfferDetailSection = ({
  setQuoteDetails,
}: {
  setQuoteDetails: React.Dispatch<
    React.SetStateAction<{
      selectedOffer: Pick<
        MarketplaceOfferType,
        "equipmentPriceRuleId" | "providerId"
      > | null;
      showPanel: boolean;
    }>
  >;
}) => {
  const [, setSearchParams] = useSearchParams();
  const MobileBookAndQuoteButtons = ({ className }: { className?: string }) => {
    return (
      <div className={cn("flex w-full flex-col gap-2.5", className)}>
        <div className="flex w-full flex-row items-center gap-2.5 lg:flex-col lg:items-start">
          <ClintButton
            as="Button"
            type="button"
            onClick={() => {
              setSearchParams((oldParams) => {
                oldParams.set("step", "4");
                return oldParams;
              });
            }}
            iconPosition="right"
            variant={"primary"}
            className="w-full grow"
            labelSize="L"
          >
            Réserver
          </ClintButton>
          <ClintButton
            as="Button"
            hideIcon
            iconPosition="right"
            variant={"tertiary"}
            className="w-full grow"
            labelSize="L"
            onClick={() => {
              setQuoteDetails((oldData) => ({ ...oldData, showPanel: true }));
            }}
          >
            Obtenir un devis
          </ClintButton>
        </div>

        {selectedFilters.plans === "1" ? null : (
          <Label
            size="XS"
            variant={"secondary"}
            className="text-center text-gray-500"
          >
            Traitement et location facturés en fin de prestation au réel
          </Label>
        )}
      </div>
    );
  };

  const { offer, offerItemDescription } = usePublicLayout() || {}; // useLoaderData<typeof loader>();

  const { selectedFilters, setSelectedFilters } = useMarketplaceSearchBar({
    defaultService: 1,
    startDate: offer?.offer.bookingStartDate
      ? formatDate({
          date: offer?.offer.bookingStartDate,
          dateFmt: "dd/MM/yyyy",
        })
      : undefined,
    endDate: offer?.offer.bookingEndDate
      ? formatDate({
          date: offer?.offer.bookingEndDate,
          dateFmt: "dd/MM/yyyy",
        })
      : undefined,
    defaultWasteType: offer?.offer.waste.gcId,
  });

  const { isProfessional } = selectedFilters;
  if (!offer || !offerItemDescription) return null;

  const {
    pricingDetails: {
      planPriceHT,
      planPriceTTC,
      tonPriceHT,
      tonPriceTTC,
      bookingPrices: { priceHT, priceTTC },
      transportData: { transportDeliveryPriceHT, transportDeliveryPriceTTC },
      treatmentData: {
        treatmentPricePerTonHT,
        equipmentWeight,
        treatmentPricePerTonTTC,
      },
      rentData: { rentPricePerDayHT, rentPricePerDayTTC },
    },
  } = offer;

  const isPro = isProfessional === "1";
  const displayedPrice = isPro ? priceHT : priceTTC;

  const displayedTransportPrice = isPro
    ? transportDeliveryPriceHT
    : transportDeliveryPriceTTC;

  const displayedTreatmentPricePerTon = isPro
    ? treatmentPricePerTonHT
    : treatmentPricePerTonTTC;
  const displayedRentPricePerDay = isPro
    ? rentPricePerDayHT
    : rentPricePerDayTTC;

  const displayedPlanPrice = isPro ? planPriceHT : planPriceTTC;
  const displayedTonPrice = isPro ? tonPriceHT : tonPriceTTC;

  useEffect(() => {
    setQuoteDetails((quoteDetails) => ({
      ...quoteDetails,
      selectedOffer: {
        equipmentPriceRuleId: offer.offer.equipment.priceRuleId,
        providerId: offer.offer.provider.id,
      },
    }));
  }, [offer]);
  const user = useOptionalUser();

  return (
    <>
      <MobileBookAndQuoteButtons className="fixed bottom-0 z-20 flex w-full flex-col border-t border-gray-300 bg-gray-100 p-4 lg:hidden" />
      <div className="relative flex h-full w-full flex-col gap-4 bg-white pt-5 lg:min-h-screen lg:flex-row lg:bg-gray-100 lg:px-10">
        {/* <NeedHelpSideSection /> */}

        <div className="flex w-full max-w-full max-lg:px-5 lg:basis-[758px] grow flex-col items-start gap-10">
          <div className="flex w-full flex-col gap-4">
            <ImageSlideShow
              key={offer.offer.equipment.priceRuleId}
              assets={offer.offer.assets}
            />

            <div className="flex flex-col gap-4">
              <SwitchBar
                options={
                  offer?.offer.otherEquipmentPriceRules.map((e) => ({
                    name: e.equipment.name,
                    value: e.id.toString(),
                  })) || []
                }
                defaultOption={offer?.offer.equipment.priceRuleId.toString()}
                onSwitch={(selectedEquipmentPriceRuleId) => {
                  setSearchParams(
                    (oldParams) => {
                      oldParams.set(
                        "equipmentPriceRuleId",
                        selectedEquipmentPriceRuleId,
                      );
                      return oldParams;
                    },
                    {
                      preventScrollReset: true,
                    },
                  );
                }}
              />
              <div className="flex overflow-x-auto gap-2">
                <Tag
                  type="Square"
                  variant="Light"
                  className="whitespace-nowrap"
                >
                  {`Largeur : ${offer?.offer.equipment.width || 0}cm`}
                </Tag>
                <Tag
                  type="Square"
                  variant="Light"
                  className="whitespace-nowrap"
                >
                  {`Hauteur : ${offer?.offer.equipment.height || 0}cm`}
                </Tag>
                <Tag
                  type="Square"
                  variant="Light"
                  className="whitespace-nowrap"
                >
                  {`Longueur : ${offer?.offer.equipment.length || 0}cm`}
                </Tag>
              </div>
            </div>
          </div>
          <OfferInformation
            equipmentWeight={equipmentWeight}
            className="hidden lg:flex"
          />
        </div>
        <div className="flex h-fit lg:basis-[586px] grow flex-col gap-6 lg:rounded-[8px] border-y lg:border border-gray-300 bg-white p-6 ">
          <div className="flex w-full items-center justify-between gap-2">
            <div className="flex flex-col">
              <Headline size="h4">{`${offer.offer.equipment.type.name} - ${offer.offer.equipment.volume}m3`}</Headline>
              <Label size="M" variant={"secondary"} className="text-gray-600">
                {offer.offer.waste.name}
              </Label>
              {user?.authOptions.isAdmin ? (
                <Paragraph size="lg" className="py-1 font-bold text-rouge">
                  {offer.offer.name}
                </Paragraph>
              ) : null}
            </div>
            <div className="flex flex-col">
              <Label size="M" variant={"secondary"}>
                À partir de
              </Label>
              <div className="flex items-center gap-2">
                <Headline size={"h4"} as="div" className="whitespace-nowrap">
                  {formatPriceWithCurrency(displayedPrice, true)}
                </Headline>
                <Label size="S" variant={"secondary"} className="text-gray-500">
                  {isPro ? "HT" : "TTC"}
                </Label>
              </div>
            </div>
          </div>
          <DoubleSelect
            onClick={(newValue) => {
              setSelectedFilters((oldData) => ({
                ...oldData,
                plans: newValue === "1" ? "1" : "0",
              }));
              setSearchParams(
                (oldParams) => {
                  oldParams.set("plans", newValue === "1" ? "1" : "0");
                  return oldParams;
                },
                {
                  preventScrollReset: true,
                },
              );
            }}
            firstOption={{
              recommanded: true,
              id: "0",
              name: "tons",
              subtitle: "Payez le prix réel de votre chargement.",
              title:
                selectedFilters.immediatePickup === "1"
                  ? "Prix à la tonne (enlèvement immédiat)"
                  : "Prix à la tonne",
              value: "0",
              mainLabel: `À partir de ${formatPriceWithCurrency(displayedTonPrice, true)} ${isPro ? "HT" : "TTC"}`,
              subLabel: `+ ${formatPriceWithCurrency(displayedTreatmentPricePerTon, true)} ${isPro ? "HT" : "TTC"} par tonne supplémentaire.`,
            }}
            name="price"
            secondOption={{
              disabled: selectedFilters.immediatePickup === "1",
              id: "1",
              name: "plans",
              subtitle: `${equipmentWeight} tonne(s) comprise(s)`,
              title: "Offre forfaitaire",
              value: "1",
              mainLabel: `${formatPriceWithCurrency(displayedPlanPrice || 0, true)} ${isPro ? "HT" : "TTC"}`,
              subLabel: `+ ${formatPriceWithCurrency(displayedTreatmentPricePerTon, true)} ${isPro ? "HT" : "TTC"} par tonne supplémentaire.`,
            }}
            defaultOption={selectedFilters.plans}
          />
          <MobileBookAndQuoteButtons className="hidden lg:flex" />

          <div className="flex flex-col gap-2">
            <BookingPriceDetailItem
              tooltip="show"
              description={`Prix de traitement ${offer.offer.waste.name.toLowerCase()} à la tonne`}
              wrapperDescription={
                "Correspond au prix à la tonne qui sera facturé sur la facture finale après pesée de la benne en centre agrée. Le calcul se fera à la dizaine de Kg près."
              }
              wrapperTitle="Prix de traitement :"
              icon={
                <ArrowTriangle aria-hidden="true" className="shrink-0 size-5" />
              }
              subValue={`+ ${formatPriceWithCurrency(displayedTreatmentPricePerTon, true)} ${isPro ? "HT" : "TTC"} / tonne`}
            />
            <BookingPriceDetailItem
              tooltip="show"
              description={`Location benne prix journalier`}
              wrapperTitle="Prix de location :"
              wrapperDescription={
                "Correspond au prix à la journée d'immobilisation de la benne. Chaque journée entamée est due."
              }
              icon={<Clock aria-hidden="true" className="shrink-0 size-5" />}
              subValue={`+ ${formatPriceWithCurrency(displayedRentPricePerDay, true)} ${isPro ? "HT" : "TTC"} / Jour`}
            />
            <BookingPriceDetailItem
              tooltip="show"
              description={`Rotation supplémentaire`}
              wrapperTitle="Prix de rotation :"
              wrapperDescription={
                "Correspond au prix d'une rotation ou d'un transport supplémentaire entre le centre de traitement et votre site. Si vous souhaitez réserver des rotations supplémentaires : pensez à nous contacter 48h à l'avance pour organiser le transport. "
              }
              icon={
                <RefreshDouble aria-hidden="true" className="shrink-0 size-5" />
              }
              subValue={`+ ${formatPriceWithCurrency(displayedTransportPrice, true)} ${isPro ? "HT" : "TTC"} / Rotation`}
            />
          </div>
        </div>
        <OfferInformation
          equipmentWeight={equipmentWeight}
          className="flex pt-8 lg:hidden"
        />
      </div>
    </>
  );
};
const NeedHelpSideSection = () => {
  const { phoneDisplay, phoneLink, CONTACT_EMAIL } = useEnv() || {};
  return (
    <div className="flex w-full max-w-full flex-col border border-gray-200 bg-white lg:max-w-[280px]">
      <Headline
        size={"h6"}
        as="div"
        className="hidden h-[88px] items-center justify-start border-gray-200 pl-4 lg:flex lg:border-b"
      >
        Besoin d'aide ?
      </Headline>
      <div className="flex w-full flex-col gap-4 p-4">
        <ClintButton
          as="Link"
          iconPosition="left"
          linkProps={{
            to: phoneLink || "#",
            reloadDocument: true,
          }}
          variant="secondaryGray100"
          size="lg"
          Icon={Phone}
        >
          {phoneDisplay}
        </ClintButton>
        <ClintButton
          as="Link"
          iconPosition="left"
          linkProps={{
            to: `mailto:${CONTACT_EMAIL}` || "#",
            reloadDocument: true,
          }}
          variant="secondaryGray100"
          size="lg"
          Icon={Mail}
        >
          {CONTACT_EMAIL}
        </ClintButton>
      </div>
    </div>
  );
};

const DescriptionItem = ({
  item: { title, content },
  type = "good",
  action = null,
}: {
  item: ReturnType<typeof getOfferItemDescription>["howItWorks"];
  type?: "good" | "bad" | "warning" | "rotate";
  action?: React.ReactNode;
}) => {
  const IconComp =
    type === "good"
      ? Check
      : type === "bad"
        ? Xmark
        : type === "warning"
          ? InfoCircle
          : type === "rotate"
            ? RefreshCircle
            : Check;
  return (
    <div className="flex w-full flex-col gap-3 lg:gap-5 lg:rounded-[8px] lg:border lg:border-gray-300 lg:p-6">
      <div className="flex items-center gap-2">
        <Tag
          type="Square"
          variant="Light"
          className={cn(`flex aspect-square items-center justify-center`, {
            "border-none bg-[#FFE8ED] text-[#FF4874]": type === "bad",
            "border-none bg-[#FFE4BC] text-[#835A1D]": type === "warning",
          })}
        >
          <IconComp
            aria-hidden="true"
            className="size-6 shrink-0"
            strokeWidth={2}
          />
        </Tag>
        <Headline size="h6" variant={"secondary"}>
          {title}
        </Headline>
      </div>
      <Markdown
        content={content}
        className="prose prose-p:text-start prose-p:text-sm prose-li:text-sm mx-0 mt-0 w-fit list-outside list-disc items-start text-start"
      />
      {action}
    </div>
  );
};

type DetailItemWithoutTooltip = {
  description: string;
  subValue?: React.ReactNode;
  icon?: React.ReactNode;
  hideIcon?: boolean;
  tooltip: "hide";
  valueClassName?: string;
  titleClassName?: string;
};

type DetailItemWithTooltip = {
  description: string;
  wrapperTitle: string;
  wrapperDescription: string;
  subValue?: React.ReactNode;
  icon?: React.ReactNode;
  hideIcon?: boolean;
  tooltip: "show";
  valueClassName?: string;
  titleClassName?: string;
};
const BookingPriceDetailItem = (
  props: DetailItemWithoutTooltip | DetailItemWithTooltip,
) => {
  const {
    description,
    subValue = null,
    icon = null,
    hideIcon = false,
    valueClassName,
    titleClassName,
    tooltip,
  } = props;
  const Wrapper = () => {
    if (tooltip === "hide") {
      return (
        <div className="flex items-center gap-2 flex-wrap justify-between w-full">
          <Label
            size="S"
            variant="secondary"
            className={cn("text-gray-800 whitespace-normal", titleClassName)}
          >
            {description}
          </Label>
          <Paragraph
            size="sm"
            fontWeight={"bold"}
            variant={"secondary"}
            className={cn("ml-auto", valueClassName)}
          >
            {subValue}
          </Paragraph>
        </div>
      );
    }
    const { wrapperTitle, wrapperDescription } = props;
    return (
      <div className="flex items-center gap-2 flex-wrap justify-between w-full">
        <AppTooltip
          className="flex-shrink"
          triggerComponent={
            <Label
              size="S"
              variant="secondary"
              className={cn(
                "text-gray-800 underline whitespace-normal",
                titleClassName,
              )}
            >
              {description}
            </Label>
          }
          content={
            <div className="flex max-w-[28ch] flex-col gap-2">
              <Paragraph size="md" fontWeight={"bold"}>
                {wrapperTitle}
              </Paragraph>
              <Paragraph size="md">{wrapperDescription}</Paragraph>
            </div>
          }
        />

        <Paragraph
          size="sm"
          fontWeight={"bold"}
          variant={"secondary"}
          className={cn("lg:ml-auto", valueClassName)}
        >
          {subValue}
        </Paragraph>
      </div>
    );
  };
  return (
    <div className="flex w-full flex-row items-center gap-3">
      {hideIcon ? null : (
        <Tag
          variant="Light"
          type="Square"
          className="text-teal flex aspect-square shrink-0 items-center justify-center border-none bg-green-100 p-1.5 text-green-600"
        >
          {icon}
        </Tag>
      )}
      <Wrapper />
    </div>
  );
};

export const ImageSlideShow = ({
  assets,
}: {
  assets: Awaited<ReturnType<typeof getBookingPrice>>["offer"]["assets"];
}) => {
  const images = assets.map((as) => as.fileUrl);
  if (images.length === 0) {
    images.push(equipmentOfferImage);
  }

  const [selectedImage, setSelectedImage] = useState(images[0]);

  return (
    <div className="flex w-full flex-col-reverse gap-5 rounded-[8px] bg-gray-100 p-5 lg:flex-row lg:bg-white">
      <div className="flex flex-row justify-center gap-4 lg:flex-col">
        {images.map((image, index) => (
          <button
            key={image + index}
            type="button"
            onClick={() => {
              setSelectedImage(image);
            }}
          >
            <OptimizedImage
              source={image}
              maxWidth={96}
              alt="equipment"
              containerClassName={`border-2 rounded-[8px] overflow-hidden ${selectedImage === image ? "border-secondary" : "border-gray-400"}`}
              className={cn(
                `aspect-square h-auto w-full max-w-[48px] bg-white object-contain ${selectedImage === image ? "" : "opacity-50"}`,
              )}
              imageProps={{
                minHeight: "100%",
                objectFit: "contain",
              }}
            />
          </button>
        ))}
      </div>
      <div className="flex w-full items-center justify-center bg-gray-100">
        <OptimizedImage
          source={selectedImage}
          maxWidth={646}
          height={401}
          alt="equipment"
          className="h-auto w-full object-contain"
          imageProps={{
            minHeight: "100%",
            objectFit: "contain",
          }}
        />
      </div>
    </div>
  );
};

const OfferInformation = ({
  className,
  equipmentWeight,
}: { className?: string; equipmentWeight: number }) => {
  const { offer, offerItemDescription } = usePublicLayout() || {};
  // useLoaderData<typeof loader>();
  if (!offer || !offerItemDescription) return null;
  return (
    <div className={cn("flex flex-col items-start gap-10", className)}>
      <div className="flex flex-col gap-10 px-5 lg:px-0">
        <div className="flex flex-col gap-2 ">
          <Headline size="h6" variant={"secondary"}>
            Prestation
          </Headline>
          <div className="flex flex-wrap gap-3 ">
            <CheckedItem title="Dépose d'une benne" />
            <CheckedItem title="Retrait d'une benne" />
            <CheckedItem title="Location de la benne" />
            <CheckedItem title="Traitement des déchets en centre agréé" />
            <CheckedItem title="Annulation gratuite 48h avant le jour de prestation" />
            <CheckedItem
              title={
                offer.offer.isPlan
                  ? `1 trajet & ${equipmentWeight} tonne(s) inclue(s)`
                  : "1 trajet"
              }
            />
          </div>
        </div>
        <div
          style={{
            backgroundImage: `url(${goodcollectVector})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          className="bg-secondary relative flex w-full gap-2 overflow-hidden rounded-[8px] py-4 px-6 lg:px-24 lg:py-16"
        >
          <Sparks className="text-primary size-8 shrink-0" aria-hidden="true" />
          <div className="flex w-full flex-col gap-8">
            <Headline size="h4" variant={"white"} className="max-w-[15ch]">
              Nos clients ont choisi cette solution
            </Headline>
            <div className="flex w-full flex-wrap items-center gap-5">
              <div className="flex grow flex-col gap-2">
                <Label
                  size="L"
                  variant={"secondary"}
                  className="text-green-200"
                >
                  100%
                </Label>
                <Label size="S" variant={"white"} className="">
                  Traçabilité assurée
                </Label>
              </div>
              <div className="flex grow flex-col gap-2">
                <Label
                  size="L"
                  variant={"secondary"}
                  className="text-green-200"
                >
                  +400 agences
                </Label>
                <Label size="S" variant={"white"}>
                  Présence Nationale
                </Label>
              </div>
              <div className="flex grow flex-col gap-2">
                <Label
                  size="L"
                  variant={"secondary"}
                  className="text-green-200"
                >
                  4,95/5
                </Label>
                <Label size="S" variant={"white"}>
                  Moyenne avis google
                </Label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-6 lg:gap-10">
        {/* <OfferInformationItemWrapper>
          <div className="flex w-full items-center gap-2">
            <Headline size="h5" variant={"secondary"}>
              Rotations
            </Headline>
            <Label size="S" variant="secondary" className="ml-auto underline">
              En savoir plus
            </Label>
          </div>
          <DescriptionItem
            type="rotate"
            item={{
              title: "Avez-vous besoin de faire des rotations ?",
              content:
                "Correspond au prix d'une rotation ou d'un transport supplémentaire entre le centre de traitement et votre site. Si vous souhaitez réserver des rotations supplémentaires : pensez à nous contacter 48h à l'avance pour organiser le transport. ",
            }}
            action={
              <ClintButton
                as="Button"
                type="button"
                variant={"tertiary"}
                isLoading={false}
                labelSize="M"
                labelClassName="w-full flex justify-between gap-3"
              >
                <div className="block grow basis-[100px]" />

                <span className="text-center">Ajouter rotations</span>
                <Paragraph
                  className="grow basis-[100px] whitespace-nowrap text-end text-green-700"
                  size="sm"
                  variant={"secondary"}
                  fontWeight={"bold"}
                >
                  {`+${formatPriceWithCurrency(offer.pricingDetails.transportData.transportDeliveryPriceHT, true)} HT par rotation`}
                </Paragraph>
              </ClintButton>
            }
          />
        </OfferInformationItemWrapper> */}
        <OfferInformationItemWrapper>
          <Headline size="h5" variant={"secondary"}>
            Déchets
          </Headline>
          <DescriptionItem
            type="good"
            item={offerItemDescription?.authorizedWaste}
          />
          <DescriptionItem
            type="bad"
            item={offerItemDescription?.forbiddenWaste}
          />
        </OfferInformationItemWrapper>
        <OfferInformationItemWrapper>
          <Headline size="h5" variant={"secondary"}>
            Informations importantes
          </Headline>
          <DescriptionItem
            type="good"
            item={offerItemDescription?.benneOnPublicStreet}
          />
          <DescriptionItem
            type="warning"
            item={offerItemDescription?.accessibility}
          />
        </OfferInformationItemWrapper>
        <div className="relative flex w-full flex-col gap-2 lg:gap-6 lg:rounded-[8px] lg:border lg:border-gray-300 max-lg:px-5 lg:p-6">
          <Headline size="h5" variant={"secondary"}>
            Paiement et annulations
          </Headline>
          <Markdown
            content={offerItemDescription?.paymentAndCancel.content}
            className="prose prose-p:text-start prose-p:text-sm prose-li:text-sm mx-0 mt-0 w-fit list-outside list-disc items-start text-start"
          />
        </div>
      </div>
    </div>
  );
};

const OfferInformationItemWrapper = ({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        "relative flex w-full flex-col gap-6 rounded-[8px] bg-gray-100 p-4 lg:bg-white max-lg:px-5 lg:p-6",
        className,
      )}
    >
      {children}
    </div>
  );
};

const OfferInformationPanel = ({
  closeMenu,
  open,
  type,
}: {
  open: boolean;
  closeMenu: () => void;
  type: "plan" | "ton";
}) => {
  return (
    <MarketplacePanel
      title="Prix à la tonne"
      closeMenu={closeMenu}
      open={open}
      footerAction={
        <ClintButton
          variant="tertiary"
          as="Button"
          type="button"
          labelSize="L"
          className="w-full"
          onClick={() => {
            closeMenu();
          }}
        >
          Fermer
        </ClintButton>
      }
    >
      <div className="flex flex-col gap-6">
        {type === "ton" ? (
          <>
            <div className="flex flex-col gap-2">
              <Headline variant={"secondary"} size="h5">
                Comment fonctionne le prix à la tonne ?
              </Headline>
              <Paragraph size="md" className="text-gray-700">
                Le prix à la tonne est calculé en fonction du poids total des
                déchets collectés. Après la collecte, la benne est pesée dans un
                centre de traitement agréé, et un ticket de pesée vous est
                fourni. Le coût final est déterminé selon le tarif appliqué par
                tonne, assurant une facturation juste et précise qui reflète
                exactement la quantité de déchets traitée.
              </Paragraph>
            </div>
            <div className="flex flex-col gap-2">
              <Headline variant={"secondary"} size="h6">
                Estimer le tonnage de son chargement
              </Headline>
              <Paragraph size="md" className="text-gray-700">
                Pour estimer le tonnage de votre chargement, commencez par
                évaluer le type et la densité des déchets que vous avez. Les
                matériaux légers comme le papier ou le plastique pèsent moins
                par volume que les matériaux lourds comme le métal ou le béton.
                Estimez ensuite le volume en m³ et multipliez-le par la densité
                en kg/m³. Cette estimation vous aidera à prévoir les coûts et à
                optimiser l'utilisation de la benne. Si vous avez besoin d'aide,
                contactez nos experts.
              </Paragraph>
            </div>
          </>
        ) : (
          <div className="flex flex-col gap-2">
            <Headline variant={"secondary"} size="h5">
              Comment fonctionne le prix forfaitaire ?
            </Headline>
            <Paragraph size="md" className="text-gray-700">
              Le prix forfaitaire comprend plusieurs éléments : le transport
              pour la dépose et le retrait de la benne, la location pendant la
              période demandée, et le traitement des déchets. Ce forfait est
              basé sur un tonnage moyen, calculé en utilisant nos règles de
              densité moyenne et multiplié par la taille de la benne choisie. En
              cas de rotation supplémentaire, de durée d'immobilisation
              prolongée ou si le tonnage dépasse le forfait, des suppléments
              seront facturés. Cette approche assure une tarification
              transparente et simplifiée, couvrant tous les aspects de la
              gestion des déchets.
            </Paragraph>
          </div>
        )}
      </div>
    </MarketplacePanel>
  );
};

const OfferQuotePanel = ({
  closeMenu,
  open,
  offer,
  title,
  selectedFilters,
  setSelectedFilters,
  setIsSearchFiltersOpen,
}: {
  open: boolean;
  closeMenu: () => void;
  offer: Pick<
    MarketplaceOfferType,
    "equipmentPriceRuleId" | "providerId"
  > | null;
  title: string;
  selectedFilters: ReturnType<
    typeof useMarketplaceSearchBar
  >["selectedFilters"];
  setSelectedFilters: ReturnType<
    typeof useMarketplaceSearchBar
  >["setSelectedFilters"];
  setIsSearchFiltersOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [searchParams] = useSearchParams();

  const getQuoteFetcher = useFetcher<typeof action>();
  const [form, fields] = useForm({
    id: "create-quote-form",
    constraint: getZodConstraint(GenerateLeadQuoteSchema),
    onValidate({ formData }) {
      const parsed = parseWithZod(formData, {
        schema: GenerateLeadQuoteSchema,
      });
      return parsed;
    },
    lastResult: getQuoteFetcher?.data?.result,
  });

  const isLoading = useIsSubmitting() || getQuoteFetcher.state !== "idle";

  const [phone, setPhone] = useState("");
  const [siret, setSiret] = useState("");

  useEffect(() => {
    if (!offer) return;
    searchParams.set("providerId", offer.providerId.toString());
    searchParams.set(
      "equipmentPriceRuleId",
      offer.equipmentPriceRuleId.toString(),
    );
  }, [offer]);

  searchParams.set("step", "3");

  return (
    <>
      <MarketplacePanel
        title={title}
        closeMenu={closeMenu}
        open={open}
        footerAction={
          <div className="flex w-full flex-col gap-2">
            <ErrorList id={form.id} errors={form.errors} />
            <ErrorList
              id={fields.providerId.id}
              errors={fields.providerId.errors}
            />
            <ErrorList
              id={fields.equipmentPriceRuleId.id}
              errors={fields.equipmentPriceRuleId.errors}
            />
            <ErrorList
              id={fields.customerType.id}
              errors={fields.customerType.errors}
            />
            <ClintButton
              variant={"tertiary"}
              as="Button"
              type="button"
              onClick={() => {
                setIsSearchFiltersOpen(true);
              }}
            >
              Modifier ma recherche
            </ClintButton>
            <Paragraph size="xs">
              En cas de changement d'adresse, nous devons recalculer nos offres.
            </Paragraph>
            {/* <ClintButton
              variant="primary"
              as="Button"
              type="submit"
              labelSize="L"
              form={form.id}
              isLoading={isLoading}
              disabled={isLoading}
              className="w-full"
            >
              Recevoir le devis par mail
            </ClintButton> */}
          </div>
        }
      >
        <getQuoteFetcher.Form
          className="flex flex-col gap-6"
          method="POST"
          action={`?${searchParams.toString()}`}
          {...getFormProps(form)}
        >
          <div className="flex flex-col gap-x-8 gap-y-4">
            <TwoSidedFormInput
              leftComponent={
                <DatepickerInput
                  inputProps={{
                    ...getInputProps(fields.startDate, { type: "text" }),
                  }}
                  onDateChange={(newDates) => {
                    setSelectedFilters((oldDates) => ({
                      ...oldDates,
                      startDate: newDates.startDate,
                    }));
                  }}
                  selectedStartDate={selectedFilters.startDate}
                  selectedEndDate=""
                  labelProps={{
                    children: "Date de dépot",
                    className: "font-medium",
                  }}
                  showRecurring
                  errors={fields.startDate.errors}
                />
              }
              rightComponent={
                <DatepickerInput
                  minDate={selectedFilters.startDate}
                  onDateChange={(newDates) => {
                    setSelectedFilters((oldDates) => ({
                      ...oldDates,
                      endDate: newDates.startDate,
                    }));
                  }}
                  selectedStartDate={selectedFilters.endDate}
                  selectedEndDate=""
                  labelProps={{
                    children: "Date de retrait (optionnelle)",
                    className: "font-medium",
                  }}
                  showRecurring
                  errors={fields.endDate.errors}
                />
              }
            />

            <div className="my-0 w-full border-t border-gray-200" />

            {/* <ClintButton as="Button" type="button" variant="tertiary">
              Ajouter une date de rotation supplémentaire
            </ClintButton> */}

            <ErrorList
              id={fields.customerType.errorId}
              errors={fields.customerType.errors}
            />
            <TwoSidedFormInput
              leftComponent={
                <Field
                  labelProps={{ children: "Prénom", className: "font-medium" }}
                  inputProps={{
                    ...getInputProps(fields.firstname, { type: "text" }),

                    autoComplete: "given-name",
                  }}
                  errors={fields.firstname.errors}
                />
              }
              rightComponent={
                <Field
                  labelProps={{ children: "Nom", className: "font-medium" }}
                  inputProps={{
                    ...getInputProps(fields.lastname, { type: "text" }),
                    autoComplete: "family-name",
                  }}
                  errors={fields.lastname.errors}
                />
              }
            />

            <TwoSidedFormInput
              leftComponent={
                <Field
                  labelProps={{ children: "Email", className: "font-medium" }}
                  inputProps={{
                    ...getInputProps(fields.email, { type: "email" }),
                    autoComplete: "email",
                  }}
                  errors={fields.email.errors}
                />
              }
              rightComponent={
                <PhoneField
                  labelProps={{
                    children: "Téléphone",
                    className: "font-medium",
                  }}
                  inputProps={{
                    ...getInputProps(fields.phone, { type: "tel" }),
                    autoComplete: "phone",
                    onChange: (newPhone: string) => {
                      setPhone(newPhone);
                    },
                    value: phone,
                  }}
                  errors={fields.phone.errors}
                />
              }
            />

            {selectedFilters.isProfessional === "1" ? (
              <>
                <Field
                  labelProps={{
                    children: "Nom de société",
                    className: "font-medium",
                  }}
                  inputProps={{
                    ...getInputProps(fields.companyName, { type: "text" }),
                    autoComplete: "company",
                  }}
                  errors={fields.companyName.errors}
                />

                <TwoSidedFormInput
                  leftComponent={
                    <Field
                      labelProps={{
                        children: "Numéro SIRET",
                        className: "font-medium",
                      }}
                      inputProps={{
                        ...getInputProps(fields.siret, { type: "text" }),
                        inputMode: "numeric",
                        pattern: "[0-9 ]*",

                        onChange: (e) => setSiret(formatSiret(e.target.value)),
                        value: siret,
                      }}
                      errors={fields.siret.errors}
                    />
                  }
                  rightComponent={
                    <Field
                      labelProps={{
                        children: "Numéro de TVA (optionnel)",
                        className: "font-medium",
                      }}
                      inputProps={{
                        ...getInputProps(fields.tvaNumber, { type: "text" }),
                        maxLength: 13,
                      }}
                      errors={fields.tvaNumber.errors}
                    />
                  }
                />
                <AddressCombobox
                  theme="default"
                  inputName={
                    getInputProps(fields.billingAddress, {
                      type: "text",
                    }).name
                  }
                  placeIdInputName={
                    getInputProps(fields.billingPlaceId, {
                      type: "text",
                    }).name
                  }
                  inputProps={{
                    ...getInputProps(fields.billingAddress, {
                      type: "text",
                    }),
                  }}
                  labelProps={{
                    children: "Adresse de facturation (optionnelle)",
                    className: "font-medium",
                  }}
                />
              </>
            ) : null}
            <div className="flex flex-col gap-2">
              <ClintButton
                variant="primary"
                as="Button"
                type="submit"
                labelSize="L"
                form={form.id}
                isLoading={isLoading}
                disabled={isLoading}
                className="w-full"
              >
                Recevoir le devis par mail
              </ClintButton>
              {/* <ClintButton
                variant={"tertiary"}
                as="Button"
                type="button"
                onClick={() => {
                  setIsSearchFiltersOpen(true);
                }}
              >
                Modifier ma recherche
              </ClintButton>
              <Paragraph size="xs">
                En cas de changement d'adresse, nous devons recalculer nos
                offres.
              </Paragraph> */}
            </div>
            <HiddenInputsArray
              inputs={[
                {
                  name: getInputProps(fields.providerId, {
                    type: "hidden",
                  }).name,
                  value: offer?.providerId,
                },
                {
                  name: getInputProps(fields.equipmentPriceRuleId, {
                    type: "hidden",
                  }).name,
                  value: offer?.equipmentPriceRuleId.toString(),
                },
                {
                  name: getInputProps(fields.address, {
                    type: "hidden",
                  }).name,
                  value: selectedFilters.address,
                },
                {
                  name: getInputProps(fields.placeId, {
                    type: "hidden",
                  }).name,
                  value: selectedFilters.placeId,
                },
                {
                  name: getInputProps(fields.startDate, {
                    type: "hidden",
                  }).name,
                  value: selectedFilters.startDate,
                },
                {
                  name: getInputProps(fields.endDate, {
                    type: "hidden",
                  }).name,
                  value: selectedFilters.endDate,
                },
                {
                  name: getInputProps(fields.customerType, {
                    type: "hidden",
                  }).name,
                  value:
                    selectedFilters.isProfessional === "1"
                      ? "professionnal"
                      : "individual",
                },
                {
                  name: "action",
                  value: "quote" as z.infer<
                    typeof GenerateLeadQuoteSchema
                  >["action"],
                },
              ]}
            />
          </div>
        </getQuoteFetcher.Form>
      </MarketplacePanel>
    </>
  );
};
export const FiltersModal = ({
  selectedFilters,
  setSelectedFilters,
  isOpen,
  setIsOpen,
}: {
  selectedFilters: ReturnType<
    typeof useMarketplaceSearchBar
  >["selectedFilters"];
  setSelectedFilters: ReturnType<
    typeof useMarketplaceSearchBar
  >["setSelectedFilters"];
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const wasteTypes = useWasteTypes();
  const isLoading = useNavigation().state === "loading";
  return (
    <ModalWrapper
      isOpen={isOpen}
      childrenClassName="overflow-visible "
      className="overflow-visible"
      setIsOpen={setIsOpen}
      modalTitle="Modifier ma recherche"
      footer={
        <Form method="GET" action="/solutions/rechercher">
          <HiddenInputsArray
            inputs={[
              { name: "address", value: selectedFilters.address },
              { name: "placeId", value: selectedFilters.placeId },
              { name: "isProfessional", value: selectedFilters.isProfessional },
              { name: "waste", value: selectedFilters.waste || "" },
              { name: "service", value: selectedFilters.service },
              { name: "step", value: selectedFilters.waste ? "2" : "1" },
            ]}
          />
          <ClintButton
            as="Button"
            type="submit"
            variant="primary"
            isLoading={isLoading}
            disabled={isLoading}
            className="w-full"
          >
            Nouvelle recherche
          </ClintButton>
        </Form>
      }
    >
      <div className="flex flex-col gap-6">
        <SwitchBar
          options={[
            {
              name: "Professionnel",
              value: "1",
            },
            {
              name: "Particulier",
              value: "0",
            },
          ]}
          defaultOption={selectedFilters.isProfessional === "1" ? "1" : "0"}
          onSwitch={(newValue) => {
            setSelectedFilters((oldFilters) => ({
              ...oldFilters,
              isProfessional: newValue === "1" ? "1" : "0",
            }));
          }}
        />
        <GeneralCombobox
          type="single"
          options={wasteTypes.map((wt) => ({
            id: wt.gcId.toString(),
            name: wt.name,
          }))}
          icon={<Trash />}
          selectedOptionId={selectedFilters.waste}
          onChange={(newWasteValue) => {
            setSelectedFilters((oldFilters) => ({
              ...oldFilters,
              waste: newWasteValue,
            }));
          }}
        />
        <div className="flex flex-col gap-2">
          <AddressCombobox
            inputName="address"
            inputProps={{
              value: selectedFilters.address,
            }}
            labelProps={{
              children: "Adresse de livraison",
            }}
            onAddressChange={(newAddress) => {
              setSelectedFilters((oldFilters) => ({
                ...oldFilters,
                address: newAddress.address,
                placeId: newAddress.placeId,
              }));
            }}
            placeIdInputName="placeId"
            defaultAddressValue={selectedFilters.address}
            defaultPlaceIdValue={selectedFilters.placeId}
          />
          <Paragraph size="xs" variant="secondaryDarker">
            En cas de changement d'adresse, nous devons recalculer nos offres.
          </Paragraph>
        </div>
      </div>
    </ModalWrapper>
  );
};

const OfferBookingSection = ({
  setQuoteDetails,
}: {
  setQuoteDetails: React.Dispatch<
    React.SetStateAction<{
      selectedOffer: Pick<
        MarketplaceOfferType,
        "equipmentPriceRuleId" | "providerId"
      > | null;
      showPanel: boolean;
    }>
  >;
}) => {
  const { offer, offerItemDescription } = usePublicLayout() || {};
  const [rerenderError, setRerenderError] = useState(false);

  useEffect(() => {
    if (!offer) return;
    setQuoteDetails((oldDetails) => ({
      ...oldDetails,
      selectedOffer: {
        equipmentPriceRuleId: offer.offer.equipment.priceRuleId,
        providerId: offer.offer.provider.id,
      },
    }));
  }, [offer]);

  if (!offer || !offerItemDescription) return null;

  const {
    pricingDetails: {
      bookingPrices: { priceHT, priceTTC, tvaFee },
      transportData: {
        transportDeliveryPriceHT,
        transportDeliveryPriceTTC,
        transportPickupPriceHT,
        transportPickupPriceTTC,
      },
      treatmentData: {
        treatmentPricePerTonHT,
        equipmentWeight,
        treatmentPricePerTonTTC,
        treatmentPriceHT,
        treatmentPriceTTC,
      },
      rentData: {
        rentPricePerDayHT,
        rentDays,
        rentPriceHT,
        rentPriceTTC,
        rentPricePerDayTTC,
        billedHours,
        pricePerHourHT,
        pricePerHourTTC,
      },
    },
  } = offer;

  const user = useOptionalUser();
  const actionData = useActionData<typeof action>();

  const [localStorageData, setLocalStorageData] = useLocalStorage<{
    startDate: string;
    endDate: string;
    firstname: string;
    lastname: string;
    phone: string;
    purchaseOrder?: string;
    comment: string;
  }>("book-details", {
    comment: "",
    endDate: "",
    firstname: "",
    lastname: "",
    phone: "",
    startDate: "",
    purchaseOrder: "",
  });

  const { selectedFilters, setSelectedFilters } = useMarketplaceSearchBar({
    defaultService: 1,
    defaultWasteType: offer?.offer.waste.gcId,
    defaultEndDate: localStorageData.endDate,
    defaultStartDate: localStorageData.startDate,
  });

  const { isProfessional } = selectedFilters;

  const [form, fields] = useForm({
    id: "create-booking-form",
    lastResult: actionData?.result,
    constraint: getZodConstraint(GenerateBookingSchema),
    onValidate({ formData }) {
      const parsed = parseWithZod(formData, {
        schema: GenerateBookingSchema,
      });
      if (parsed.status === "error") {
        setRerenderError((value) => !value);
      }

      return parsed;
    },
    defaultValue: {
      comment: localStorageData.comment,
      contactFirstName: localStorageData.firstname,
      contactLastName: localStorageData.lastname,
      contactPhone: localStorageData.phone,
      startDate: localStorageData.startDate,
      endDate: localStorageData.endDate,
      purchaseOrder: localStorageData.purchaseOrder,
    },
  });

  const isPro = isProfessional === "1";
  const displayedTransportDeliveryPrice = isPro
    ? transportDeliveryPriceHT
    : transportDeliveryPriceTTC;

  const displayedTransportPickupPrice = isPro
    ? transportPickupPriceHT
    : transportPickupPriceTTC;

  const displayedTreatmentPricePerTon = isPro
    ? treatmentPricePerTonHT
    : treatmentPricePerTonTTC;
  const displayedTreatmentPrice = isPro ? treatmentPriceHT : treatmentPriceTTC;
  const displayedRentPrice = isPro ? rentPriceHT : rentPriceTTC;
  const displayedRentPricePerDay = isPro
    ? rentPricePerDayHT
    : rentPricePerDayTTC;

  const displayedPricePerHour = isPro ? pricePerHourHT : pricePerHourTTC;

  const isLoading = useIsSubmitting();
  const [sameDeliveryAddressForBilling, setSameDeliveryAddressForBilling] =
    useState(true);

  const [phone, setPhone] = useState(localStorageData.phone || "");
  const [siret, setSiret] = useState("");

  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const customerFields = fields.customer.getFieldset();
  const submitLogout = useSubmit();

  const MobileBookAndQuoteButtons = ({ className }: { className?: string }) => {
    return (
      <div
        className={cn("flex rounded-t-lg w-full flex-col gap-2.5", className)}
      >
        <div className="flex  justify-between gap-2 lg:py-4 pb-3.5 lg:pb-6">
          <Paragraph
            size="md"
            variant="secondaryDarker"
            className="font-medium  "
          >
            À régler maintenant
          </Paragraph>
          <Headline size="h5" variant="secondaryDarker">
            {formatPriceWithCurrency(priceTTC)}
          </Headline>
        </div>
        <ErrorList errors={form.errors} />
        <div className="flex w-full flex-row items-center gap-2.5 ">
          <ClintButton
            disabled={isLoading}
            isLoading={isLoading}
            as="Button"
            variant="primary"
            name="action"
            value="booking"
            type="submit"
            className="grow h-12 lg:h-[52px]"
            labelSize="L"
            form={form.id}
            onClick={() => {
              if (typeof window === "undefined") return;
              window.localStorage.removeItem("book-details");
            }}
          >
            Finaliser
          </ClintButton>
          <Paragraph
            size="sm"
            className="hidden lg:block"
            variant={"secondary"}
          >
            ou
          </Paragraph>
          <ClintButton
            disabled={isLoading}
            isLoading={isLoading}
            as="Button"
            variant="tertiary"
            type="button"
            onClick={() => {
              setQuoteDetails((oldData) => ({ ...oldData, showPanel: true }));
            }}
            name="action"
            value="quote"
            className="grow h-12 lg:h-[52px]"
            labelSize="L"
          >
            Obtenir un devis
          </ClintButton>
        </div>

        {selectedFilters.plans === "1" ? null : (
          <Label
            size="XS"
            variant={"secondary"}
            className="text-center text-gray-500"
          >
            Traitement et location facturés en fin de prestation au réel
          </Label>
        )}
      </div>
    );
  };
  const [, setSearchParams] = useSearchParams();

  return (
    <>
      <MobileBookAndQuoteButtons className="fixed bottom-0 z-20 flex w-full flex-col border-t border-gray-300 bg-gray-100 p-4 lg:hidden" />

      <LoginModal isOpen={isLoginOpen} setIsOpen={setIsLoginOpen} />

      <div className="flex items-center gap-4 px-5 py-4 lg:px-10">
        <button
          type="button"
          onClick={() => {
            setSearchParams(
              (oldParams) => {
                oldParams.set("step", "3");
                return oldParams;
              },
              {
                preventScrollReset: true,
              },
            );
          }}
          className={TagsVariants({
            type: "Square",
            variant: "Light",
            className: "flex aspect-square items-center justify-center",
          })}
        >
          <ArrowLeft className="size-4" aria-hidden="true" />
        </button>
        <Headline size={"h6"} as="div">
          Demande de réservation
        </Headline>
      </div>
      <div className="relative flex h-full w-full flex-col-reverse gap-4 bg-gray-100 px-0 lg:min-h-screen lg:flex-row lg:px-10">
        <div className="flex w-full max-w-full flex-1 grow flex-col items-start gap-10 lg:basis-[750px]">
          <div className="flex w-full flex-col gap-4">
            <Form
              {...getFormProps(form)}
              method="POST"
              className="flex flex-col gap-6 bg-white p-5 lg:rounded-[8px] lg:p-6"
            >
              <button type="submit" className="hidden" />
              <div className="flex flex-col gap-4">
                <Headline size="h5" variant={"secondary"}>
                  À quelle date ?
                </Headline>
                <Paragraph size="md" variant={"secondary"}>
                  Merci de bien fournir les dates réelles de votre réservation.
                </Paragraph>
              </div>
              <TwoSidedFormInput
                leftComponent={
                  <DatepickerInput
                    key={rerenderError ? "error" : "noError"}
                    inputProps={getInputProps(fields.startDate, {
                      type: "text",
                    })}
                    onDateChange={(newDates) => {
                      setLocalStorageData((oldData) => ({
                        ...oldData,
                        startDate: newDates.startDate,
                      }));
                      setSelectedFilters((oldDates) => ({
                        ...oldDates,
                        startDate: newDates.startDate,
                      }));
                      setSearchParams(
                        (oldParams) => {
                          oldParams.set(
                            "startDate",
                            newDates.startDate.toString(),
                          );
                          return oldParams;
                        },
                        {
                          preventScrollReset: true,
                        },
                      );
                    }}
                    selectedStartDate={selectedFilters.startDate}
                    selectedEndDate=""
                    labelProps={{ children: "Date de dépot" }}
                    showRecurring
                    errors={fields.startDate.errors}
                  />
                }
                rightComponent={
                  <DatepickerInput
                    inputProps={getInputProps(fields.endDate, { type: "text" })}
                    minDate={selectedFilters.startDate}
                    onDateChange={(newDates) => {
                      setLocalStorageData((oldData) => ({
                        ...oldData,
                        endDate: newDates.startDate,
                      }));
                      setSelectedFilters((oldDates) => ({
                        ...oldDates,
                        endDate: newDates.startDate,
                      }));
                      setSearchParams(
                        (oldParams) => {
                          oldParams.set(
                            "endDate",
                            newDates.startDate.toString(),
                          );
                          return oldParams;
                        },
                        {
                          preventScrollReset: true,
                        },
                      );
                    }}
                    selectedStartDate={selectedFilters.endDate}
                    selectedEndDate=""
                    labelProps={{ children: "Date de retrait (optionnelle)" }}
                    showRecurring
                    errors={fields.endDate.errors}
                  />
                }
              />
              {/* <div className="my-0 w-full border-t border-gray-200" /> */}
              {/* <div className="flex flex-col gap-2"> */}
              {/* <DatepickerInput
                  minDate={selectedDates.endDate}
                  onDateChange={(newDates) => {
                    setSelectedDates((oldDates) => ({
                      ...oldDates,
                      additionalDate: newDates.startDate,
                    }));
                  }}
                  selectedStartDate={selectedDates.additionalDate}
                  selectedEndDate=""
                  labelProps={{ children: "Date de rotation" }}
                  showRecurring
                // errors={fields.endDate.errors}
                /> */}
              {/* <Paragraph
                  size="xs"
                  variant={"secondary"}
                  className="text-gray-500 underline"
                >
                  Supprimer
                </Paragraph>
                <ClintButton as="Button" type="button" variant="tertiary">
                  Ajouter une date de rotation supplémentaire
                </ClintButton> */}
              {/* </div> */}
              <div className="my-2 w-full border-t border-gray-200" />
              <div className="flex flex-col gap-4">
                <Headline size="h5" variant={"secondary"}>
                  Adresse de livraison
                </Headline>

                <Field
                  labelProps={{ children: "Adresse" }}
                  inputProps={{
                    ...getInputProps(fields.address, { type: "text" }),
                    disabled: true,
                    value: selectedFilters.address,
                    autoComplete: "given-name",
                  }}
                  errors={fields.address.errors}
                />

                <TwoSidedFormInput
                  leftComponent={
                    <Field
                      labelProps={{ children: "Prénom" }}
                      inputProps={{
                        ...getInputProps(fields.contactFirstName, {
                          type: "text",
                        }),

                        autoComplete: "given-name",
                        onChange(newValue) {
                          setLocalStorageData((oldData) => ({
                            ...oldData,
                            firstname: newValue.target.value,
                          }));
                        },
                      }}
                      errors={fields.contactFirstName.errors}
                    />
                  }
                  rightComponent={
                    <Field
                      labelProps={{ children: "Nom" }}
                      inputProps={{
                        ...getInputProps(fields.contactLastName, {
                          type: "text",
                        }),
                        autoComplete: "family-name",
                        onChange(newValue) {
                          setLocalStorageData((oldData) => ({
                            ...oldData,
                            lastname: newValue.target.value,
                          }));
                        },
                      }}
                      errors={fields.contactLastName.errors}
                    />
                  }
                />

                <PhoneField
                  labelProps={{ children: "Téléphone" }}
                  inputProps={{
                    ...getInputProps(fields.contactPhone, { type: "tel" }),
                    autoComplete: "phone",
                    onChange: (newPhone: string) => {
                      setPhone(newPhone);
                      setLocalStorageData((oldData) => ({
                        ...oldData,
                        phone: newPhone,
                      }));
                    },
                    value: phone,
                  }}
                  errors={fields.contactPhone.errors}
                />

                <Field
                  labelProps={{ children: "Bon de commande (facultatif)" }}
                  inputProps={{
                    ...getInputProps(fields.purchaseOrder, { type: "text" }),
                    onChange(newValue) {
                      setLocalStorageData((oldData) => ({
                        ...oldData,
                        purchaseOrder: newValue.target.value,
                      }));
                    },
                  }}
                  errors={fields.purchaseOrder.errors}
                />
                <TextareaField
                  textareaProps={{
                    ...getTextareaProps(fields.comment),
                    rows: 5,
                    placeholder:
                      "Merci de nous préciser toute information que vous jugerez importante en terme d'accessibilité du lieu de dépose de la benne, déchet que vous souhaitez mettre dans la benne, contrainte horaire de dépose de la benne. ",
                    onChange(newValue) {
                      setLocalStorageData((oldData) => ({
                        ...oldData,
                        comment: newValue.target.value,
                      }));
                    },
                  }}
                  labelProps={{ children: "Commentaire libre" }}
                  errors={fields.comment.errors}
                />

                <div className="my-2 w-full border-t border-gray-200 lg:my-2" />

                <div className="flex flex-col gap-4">
                  <Headline size="h5" variant={"secondary"}>
                    Adresse de facturation
                  </Headline>

                  {user ? (
                    <>
                      <div className="flex items-center gap-2">
                        <Paragraph size="md">
                          {user.firstname} {user.lastname}
                        </Paragraph>
                        <Paragraph
                          size="md"
                          variant={"secondary"}
                          className="text-gray-600"
                        >
                          ({user.email})
                        </Paragraph>
                      </div>
                      <div className="flex items-center gap-1">
                        <Paragraph size="md" variant={"secondary"}>
                          Mauvais compte ?
                        </Paragraph>
                        <button
                          type="button"
                          name="action"
                          value="logout"
                          onClick={() => {
                            submitLogout(
                              {
                                action: "logout",
                              },
                              {
                                method: "POST",
                              },
                            );
                          }}
                          className={cn(
                            paragraphVariants({ size: "md" }),
                            "font-bold underline",
                          )}
                        >
                          Se déconnecter
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex items-center gap-1">
                        <Paragraph size="md" variant={"secondary"}>
                          Vous êtes déjà client ?
                        </Paragraph>
                        <button
                          type="button"
                          onClick={() => {
                            setIsLoginOpen(true);
                          }}
                          className={cn(
                            paragraphVariants({ size: "md" }),
                            "font-bold underline",
                          )}
                        >
                          Se connecter
                        </button>
                      </div>
                      <SwitchBar
                        itemClassName="grow"
                        className="w-full"
                        options={[
                          {
                            name: "Professionnel",
                            value: "1",
                          },
                          {
                            name: "Particulier",
                            value: "0",
                          },
                        ]}
                        defaultOption={
                          selectedFilters.isProfessional === "1" ? "1" : "0"
                        }
                        onSwitch={(newIsProfessional) => {
                          setSelectedFilters((oldFilters) => ({
                            ...oldFilters,
                            isProfessional:
                              newIsProfessional === "1" ? "1" : "0",
                          }));
                        }}
                      />
                    </>
                  )}

                  <CheckboxField
                    key={sameDeliveryAddressForBilling ? "true" : "false"}
                    buttonProps={{
                      defaultChecked: sameDeliveryAddressForBilling,
                      form: form.id,
                      name: "sameDeliveryAddressForBilling",
                      onCheckedChange: () => {
                        setSameDeliveryAddressForBilling(
                          (oldValue) => !oldValue,
                        );
                      },
                    }}
                    labelProps={{
                      children:
                        "L'adresse de facturation est la même que l'adresse de livraison",
                      className:
                        "text-secondary peer-data-[state=checked]:text-black",
                    }}
                  />

                  {sameDeliveryAddressForBilling ? (
                    <>
                      <HiddenInputsArray
                        inputs={[
                          {
                            name: getInputProps(fields.billingAddress, {
                              type: "hidden",
                            }).name,
                            value: selectedFilters.address,
                          },
                          {
                            name: getInputProps(fields.billingPlaceId, {
                              type: "hidden",
                            }).name,
                            value: selectedFilters.placeId,
                          },
                        ]}
                      />
                    </>
                  ) : (
                    <AddressCombobox
                      theme="default"
                      inputName={
                        getInputProps(fields.billingAddress, {
                          type: "text",
                        }).name
                      }
                      placeIdInputName={
                        getInputProps(fields.billingPlaceId, {
                          type: "text",
                        }).name
                      }
                      inputProps={{
                        ...getInputProps(fields.billingAddress, {
                          type: "text",
                        }),
                      }}
                      labelProps={{
                        children: "Adresse de facturation",
                      }}
                    />
                  )}

                  {user ? null : (
                    <fieldset
                      {...getFieldsetProps(fields.customer)}
                      className="flex flex-col gap-4"
                    >
                      <ErrorList errors={fields.customer.errors} />

                      <input
                        {...getInputProps(customerFields.customerType, {
                          type: "hidden",
                        })}
                        value={
                          selectedFilters.isProfessional === "1"
                            ? "professionnal"
                            : "individual"
                        }
                      />
                      <TwoSidedFormInput
                        leftComponent={
                          <Field
                            labelProps={{ children: "Prénom" }}
                            inputProps={{
                              ...getInputProps(customerFields.firstname, {
                                type: "text",
                              }),

                              autoComplete: "given-name",
                            }}
                            errors={customerFields.firstname.errors}
                          />
                        }
                        rightComponent={
                          <Field
                            labelProps={{ children: "Nom" }}
                            inputProps={{
                              ...getInputProps(customerFields.lastname, {
                                type: "text",
                              }),
                              autoComplete: "family-name",
                            }}
                            errors={customerFields.lastname.errors}
                          />
                        }
                      />

                      <Field
                        labelProps={{ children: "Email" }}
                        inputProps={{
                          ...getInputProps(customerFields.email, {
                            type: "email",
                          }),
                          autoComplete: "email",
                        }}
                        errors={customerFields.email.errors}
                      />

                      <PhoneField
                        labelProps={{ children: "Téléphone" }}
                        inputProps={{
                          ...getInputProps(customerFields.phone, {
                            type: "tel",
                          }),
                          autoComplete: "phone",
                          onChange: (newPhone: string) => {
                            setPhone(newPhone);
                          },
                          value: phone,
                        }}
                        errors={customerFields.phone.errors}
                      />

                      {selectedFilters.isProfessional === "1" ? (
                        <>
                          <Field
                            labelProps={{ children: "Société" }}
                            inputProps={{
                              ...getInputProps(customerFields.companyName, {
                                type: "text",
                              }),
                              autoComplete: "company",
                            }}
                            errors={customerFields.companyName.errors}
                          />

                          <Field
                            labelProps={{ children: "SIRET" }}
                            inputProps={{
                              ...getInputProps(customerFields.siret, {
                                type: "text",
                              }),
                              inputMode: "numeric",
                              pattern: "[0-9 ]*",

                              onChange: (e) =>
                                setSiret(formatSiret(e.target.value)),
                              value: siret,
                            }}
                            errors={customerFields.siret.errors}
                          />

                          <Field
                            labelProps={{ children: "Numéro de TVA" }}
                            inputProps={{
                              ...getInputProps(customerFields.tvaNumber, {
                                type: "text",
                              }),
                              maxLength: 13,
                            }}
                            errors={customerFields.tvaNumber.errors}
                          />
                        </>
                      ) : null}
                    </fieldset>
                  )}
                </div>

                <div
                  className={cn(`w-full border-t border-gray-200`, {
                    "my-2": user,
                    "my-5 lg:my-8": !user,
                  })}
                />

                {user ? (
                  <input
                    {...getInputProps(fields.hasAcceptedTerms, {
                      type: "hidden",
                    })}
                    value={"on"}
                  />
                ) : (
                  <>
                    <div className="flex flex-col gap-4">
                      <Label size="M" variant={"secondary"}>
                        Un compte GoodCollect sera créé avec vos informations
                        afin de suivre votre commande.
                      </Label>
                      <CheckboxField
                        buttonProps={{
                          ...getInputProps(fields.hasAcceptedTerms, {
                            type: "checkbox",
                          }),
                        }}
                        labelProps={{
                          children:
                            "J'ai lu et j'accepte les informations de location, les conditions générales, et la politique de confidentialité, et j'ai connaissance que je réserve avec un montant prépayé, impliquant que le prix total de la location soit déduit immédiatement de la carte de crédit ou de débit que j'ai fournie.",
                          className: "font-normal",
                        }}
                        errors={fields.hasAcceptedTerms.errors}
                      />
                    </div>
                  </>
                )}

                <MobileBookAndQuoteButtons
                  className={cn("hidden lg:flex", {
                    "pt-0": user,
                    "pt-5": !user,
                  })}
                />

                <HiddenInputsArray
                  inputs={[
                    {
                      name: getInputProps(fields.status, {
                        type: "hidden",
                      }).name,
                      value: user
                        ? "logged-in"
                        : ("logged-out" as z.infer<
                            typeof GenerateBookingSchema
                          >["status"]),
                    },
                    {
                      name: getInputProps(fields.providerId, {
                        type: "hidden",
                      }).name,
                      value: offer.offer.provider.id,
                    },
                    {
                      name: getInputProps(fields.equipmentPriceRuleId, {
                        type: "hidden",
                      }).name,
                      value: offer.offer.equipment.priceRuleId.toString(),
                    },
                    {
                      name: getInputProps(fields.address, {
                        type: "hidden",
                      }).name,
                      value: selectedFilters.address,
                    },
                    {
                      name: getInputProps(fields.placeId, {
                        type: "hidden",
                      }).name,
                      value: selectedFilters.placeId,
                    },
                    {
                      name: getInputProps(fields.startDate, {
                        type: "hidden",
                      }).name,
                      value: selectedFilters.startDate,
                    },
                    {
                      name: getInputProps(fields.endDate, {
                        type: "hidden",
                      }).name,
                      value: selectedFilters.endDate,
                    },
                  ]}
                />
              </div>
            </Form>
          </div>
        </div>
        <div className="flex h-fit flex-1 flex-col gap-6 bg-white p-5  lg:basis-[560px] lg:rounded-[8px] lg:border lg:border-gray-300 lg:p-6">
          <div className="flex flex-col items-start lg:flex-row w-fit lg:items-center gap-10">
            <OptimizedImage
              source={offer.offer.equipment.imageUrl || equipmentImage}
              maxWidth={256}
              alt={offer.offer.equipment.type.name}
              className="max-w-[128px]"
              containerClassName="mx-0"
              imageProps={{
                minHeight: "100%",
                objectFit: "contain",
              }}
            />
            <div className="flex flex-col gap-2">
              <Label size="M" variant="secondary">
                {`${offer.offer.equipment.type.name} - ${offer.offer.equipment.volume}m3`}
              </Label>
              <Label
                size="S"
                variant={"secondaryDarker"}
                className="text-gray-600"
              >
                {offer.offer.waste.name}
              </Label>
              <Paragraph size="sm" variant="secondaryDarker">
                {selectedFilters.address}
              </Paragraph>
            </div>
          </div>

          <div className="my-0 border-b border-gray-200" />
          <div className="flex flex-col gap-2">
            <BookingPriceDetailItem
              description={
                selectedFilters.immediatePickup === "1"
                  ? "Enlèvement immédiat"
                  : `Dépose de la benne`
              }
              icon={<Truck className="shrink-0 size-5" aria-hidden="true" />}
              subValue={`${formatPriceWithCurrency(displayedTransportDeliveryPrice, true)} ${isPro ? "HT" : "TTC"}`}
              valueClassName="font-normal"
              tooltip="hide"
              titleClassName="no-underline hover:underline"
            />
            {selectedFilters.immediatePickup === "1" ? (
              <BookingPriceDetailItem
                description={`${billedHours} heure inclue`}
                icon={
                  <Truck
                    className="-scale-x-100 transform size-5 shrink-0"
                    aria-hidden="true"
                  />
                }
                subValue={`${formatPriceWithCurrency(displayedPricePerHour, true)} ${isPro ? "HT" : "TTC"}`}
                valueClassName="font-normal"
                tooltip="hide"
                titleClassName="no-underline hover:underline"
              />
            ) : (
              <BookingPriceDetailItem
                description={`Retrait de la benne`}
                icon={
                  <Truck
                    className="-scale-x-100 transform size-5 shrink-0"
                    aria-hidden="true"
                  />
                }
                subValue={`${formatPriceWithCurrency(displayedTransportPickupPrice, true)} ${isPro ? "HT" : "TTC"}`}
                valueClassName="font-normal"
                tooltip="hide"
                titleClassName="no-underline hover:underline"
              />
            )}
            {selectedFilters.plans === "1" ? (
              <>
                <BookingPriceDetailItem
                  description={`${equipmentWeight} tonne(s) comprise(s)`}
                  icon={
                    <ArrowTriangle
                      aria-hidden="true"
                      className="size-5 shrink-0"
                    />
                  }
                  subValue={`${formatPriceWithCurrency(displayedTreatmentPrice, true)} ${isPro ? "HT" : "TTC"}`}
                  valueClassName="font-normal"
                  tooltip="hide"
                  titleClassName="no-underline hover:underline"
                />

                <BookingPriceDetailItem
                  description={`${rentDays} jours compris`}
                  icon={<Time className="size-5 shrink-0" aria-hidden="true" />}
                  subValue={`${formatPriceWithCurrency(displayedRentPrice, true)} ${isPro ? "HT" : "TTC"}`}
                  valueClassName="font-normal"
                  tooltip="hide"
                  titleClassName="no-underline hover:underline"
                />
              </>
            ) : null}
          </div>
          <div className="my-0 border-b border-gray-200" />

          <div className="flex flex-col gap-4">
            <BookingPriceDetailItem
              description={`Total HT`}
              subValue={`${formatPriceWithCurrency(priceHT, true)}`}
              valueClassName="font-normal"
              titleClassName="font-normal no-underline hover:underline"
              hideIcon
              tooltip="hide"
            />
            <BookingPriceDetailItem
              description={`TVA 20%`}
              subValue={`${formatPriceWithCurrency(tvaFee, true)}`}
              valueClassName="font-normal"
              titleClassName="font-normal no-underline hover:underline"
              hideIcon
              tooltip="hide"
            />
            <BookingPriceDetailItem
              description={`Total TTC`}
              subValue={`${formatPriceWithCurrency(priceTTC, true)}`}
              titleClassName="font-normal no-underline hover:underline"
              hideIcon
              tooltip="hide"
            />
            <BookingPriceDetailItem
              description={`À régler maintenant`}
              subValue={`${formatPriceWithCurrency(priceTTC, true)}`}
              titleClassName="font-medium mt-2 text-base no-underline"
              valueClassName="text-xl hover:underline"
              hideIcon
              tooltip="hide"
            />
          </div>

          <div className="my-0 border-b border-gray-200" />
          <div className="flex flex-col gap-2">
            <BookingPriceDetailItem
              description={`À régler en fin de prestation`}
              subValue={``}
              titleClassName="font-medium mb-2 text-base no-underline"
              hideIcon
              tooltip="hide"
            />
            <BookingPriceDetailItem
              wrapperTitle="Prix de traitement :"
              wrapperDescription="Correspond au prix à la tonne qui sera facturé sur la facture finale après pesée de la benne en centre agrée. Le calcul se fera à la dizaine de Kg près. "
              description={`Prix de traitement ${offer.offer.waste.name.toLowerCase()} à la tonne`}
              icon={
                <ArrowTriangle aria-hidden="true" className="size-5 shrink-0" />
              }
              subValue={`+ ${formatPriceWithCurrency(displayedTreatmentPricePerTon, true)} ${isPro ? "HT" : "TTC"} / tonne`}
              tooltip="show"
            />
            <BookingPriceDetailItem
              wrapperTitle="Prix de location :"
              wrapperDescription="Correspond au prix à la journée d'immobilisation de la benne. Chaque journée entamée est due."
              description={`Location benne prix journalier`}
              icon={<Time aria-hidden="true" className="shrink-0 size-5" />}
              subValue={`+ ${formatPriceWithCurrency(displayedRentPricePerDay, true)} ${isPro ? "HT" : "TTC"} / Jour`}
              tooltip="show"
            />
            <BookingPriceDetailItem
              wrapperTitle="Prix de rotation :"
              wrapperDescription="Correspond au prix d'une rotation ou d'un transport supplémentaire entre le centre de traitement et votre site. Si vous souhaitez réserver des rotations supplémentaires : pensez à nous contacter 48h à l'avance pour organiser le transport. "
              description={`Rotation supplémentaire`}
              icon={<Refresh aria-hidden="true" className="shrink-0 size-5" />}
              subValue={`+ ${formatPriceWithCurrency(displayedTransportDeliveryPrice, true)} ${isPro ? "HT" : "TTC"} / Rotation`}
              tooltip="show"
            />
          </div>
        </div>
      </div>
    </>
  );
};

const LoginModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const location = useLocation();
  const fullUrl = `${location.pathname}${location.search}`;
  const actionData = useActionData<typeof action>();

  const [form, fields] = useForm({
    id: "login-form",
    constraint: getZodConstraint(LoginSchema),
    onValidate({ formData }) {
      const parsed = parseWithZod(formData, {
        schema: LoginSchema,
      });

      return parsed;
    },
    lastResult: actionData?.result,
  });

  const isLoading = useIsSubmitting();
  return (
    <ModalWrapper
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      modalTitle="Connexion"
      footer={
        <div className="flex w-full items-center justify-center">
          <ClintButton
            as="Button"
            form={form.id}
            disabled={isLoading}
            isLoading={isLoading}
            type="submit"
            variant="primary"
            name="action"
            value="login"
            className="w-full"
          >
            Se connecter
          </ClintButton>
        </div>
      }
    >
      <Form
        {...getFormProps(form)}
        method="POST"
        className="flex flex-col gap-4"
      >
        <Field
          inputProps={{ ...getInputProps(fields.email, { type: "email" }) }}
          labelProps={{
            children: "Email",
          }}
          errors={fields.email.errors}
        />

        <Field
          inputProps={{
            ...getInputProps(fields.password, { type: "password" }),
          }}
          labelProps={{
            children: "Mot de passe",
          }}
          errors={fields.password.errors}
        />
        <input type="hidden" name="redirectTo" value={fullUrl} />
        <Link to={`/forgot-password?redirectTo${fullUrl}`}>
          <Paragraph size="md" fontWeight={"bold"} className="underline">
            Mot de passe oublié ?
          </Paragraph>
        </Link>
      </Form>
    </ModalWrapper>
  );
};
