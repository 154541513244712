import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "components/shadcn-ui/sheet";
import { useTailwindScreenSizes } from "hooks/useTailwindScreenSizes";
import { Xmark } from "iconoir-react";
import { cn } from "utils/utils";
import { Headline } from "./Headline";
import { Tag } from "./Tag";

export const MarketplacePanel = ({
  closeMenu,
  open,
  title,
  children,
  footerAction,
  className,
}: {
  open: boolean;
  closeMenu: () => void;
  title: string;
  children: React.ReactNode;
  footerAction?: React.ReactNode;
  className?: string;
}) => {
  const { isLargeScreen } = useTailwindScreenSizes({});

  return (
    <Sheet
      open={open}
      onOpenChange={() => {
        closeMenu();
      }}
    >
      <SheetContent
        side={isLargeScreen ? "right" : "bottom"}
        hideCloseIcon
        className="flex h-full flex-col overflow-y-auto p-0 lg:min-w-[626px]"
      >
        <div className="relative flex h-full flex-col">
          <SheetHeader className="flex flex-row items-center justify-between border-b border-gray-200 px-10 py-6">
            <SheetTitle asChild>
              <Headline size="h4" variant={"secondary"} className="font-medium">
                {title}
              </Headline>
            </SheetTitle>
            <SheetClose>
              <Tag variant="Light" type="Square" className="aspect-square p-2">
                <Xmark className="size-5" />
                <span className="sr-only">Fermer</span>
              </Tag>
            </SheetClose>
          </SheetHeader>
          <div className={cn("px-10 py-6", className)}>{children}</div>

          {footerAction ? (
            <SheetFooter className="sticky bottom-0 mt-auto w-full border-t border-t-gray-200 bg-gray-100 px-10 py-6">
              {footerAction}
            </SheetFooter>
          ) : null}
        </div>
      </SheetContent>
    </Sheet>
  );
};
