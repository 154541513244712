import { MarketplaceOffersType } from "@goodcollect/shared/bookings";
import { useMarketplaceSideFilters } from "hooks/useMarketplaceSideFilters.tsx";
import { MarketplaceSideFilterWrapper } from "../MarketplaceSideFilters.tsx";
import { SideFilterCheckbox } from "./SideFilterCheckbox.tsx";

export const EquipmentTypeFilter = ({
  toggleEquipmentType,
  title,
  availableEquipmentTypes,
  equipmentTypes,
  selectedEquipmentTypes,
}: {
  title: string;
  equipmentTypes: MarketplaceOffersType["equipmentTypes"];
  availableEquipmentTypes: MarketplaceOffersType["availableEquipmentTypes"];
  selectedEquipmentTypes: MarketplaceOffersType["equipmentTypes"];
  toggleEquipmentType: ReturnType<
    typeof useMarketplaceSideFilters
  >["toggleEquipmentType"];
}) => {
  return (
    <MarketplaceSideFilterWrapper title={title}>
      <div className="flex flex-col gap-4">
        {equipmentTypes.map((option) => {
          const isAvailable = availableEquipmentTypes.some(
            (aet) => aet.id === option.id,
          );
          return (
            <SideFilterCheckbox
              checked={
                isAvailable &&
                selectedEquipmentTypes.some((so) => so.id === option.id)
              }
              toggleOption={(valueId) => toggleEquipmentType(Number(valueId))}
              optionName={option.name}
              optionGcId={option.id}
              key={`equipment-type-${option.id}`}
              isDisabled={!isAvailable}
            />
          );
        })}
      </div>
    </MarketplaceSideFilterWrapper>
  );
};
