import { useEnv } from "app/root.tsx";
import { Phone } from "iconoir-react";
import React from "react";
import { formatPhone } from "utils/format-phone.ts";
export const PhoneWidget = () => {
  const GOODCOLLECT_PHONE = useEnv()?.GOODCOLLECT_PHONE || "";
  let phoneLink = "";
  if (GOODCOLLECT_PHONE) {
    phoneLink = `tel:+33${[...GOODCOLLECT_PHONE].slice(1).join("")}`;
  }

  const phoneDisplay = GOODCOLLECT_PHONE ? formatPhone(GOODCOLLECT_PHONE) : "";
  const [clicked, setClicked] = React.useState(false);
  return (
    <div className="fixed bottom-8 right-8 z-30 hidden">
      <button
        type="button"
        onClick={() => setClicked(!clicked)}
        aria-label="Appelez-nous"
        className={`${
          clicked
            ? "text-midnightblue bg-white"
            : "bg-midnightblue hover:text-midnightblue text-white hover:bg-white"
        } group flex w-fit shrink-0 items-center justify-between gap-x-3 whitespace-nowrap rounded-full px-2.5 py-1 text-sm shadow-md shadow-black/10 outline-none lg:px-4 lg:py-3`}
      >
        <Phone className="text-teal transition-all duration-100 group-hover:scale-125" />{" "}
        {clicked ? (
          <a href={phoneLink} className="whitespace-nowrap">
            {phoneDisplay}
          </a>
        ) : (
          <>
            <span className="group-hover:hidden">Appelez-nous</span>
            <a
              href={phoneLink}
              className="hidden whitespace-nowrap group-hover:block"
            >
              {phoneDisplay}
            </a>
          </>
        )}
      </button>
    </div>
  );
};
