import { type RenderableTreeNodes, renderers } from "@markdoc/markdoc";
import { useEnv } from "app/root";
import { OptimizedImage } from "components/OptimizedImage.tsx";
import * as React from "react";
import { cn } from "utils/utils.ts";

type Props = { className?: string; content: RenderableTreeNodes };
function generateIdFromTitle(title) {
  return title
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .replace(/\*\*(.*?)\*\*/g, "$1") // Supprimer le gras
    .replace(/\[(.*?)\]\(.*?\)/g, "$1") // Supprimer les liens markdown
    .replace(/`(.*?)`/g, "$1") // Supprimer le code inline
    .replace(/[^\w\s-]/g, "") // Supprimer les caractères spéciaux
    .replace(/\s+/g, "-") // Remplacer les espaces par des tirets
    .replace(/^-+|-+$/g, ""); // Supprimer les tirets au début et à la fin
}

function addIdsToHeadings(node) {
  if (node.$$mdtype === "Tag" && /^h[2-3]$/.test(node.name)) {
    if (
      node.attributes.id === "" ||
      !node.attributes.id ||
      // specific case for "et" and "ou" for strasbourg
      node.attributes.id === "et" ||
      node.attributes.id === "ou"
    ) {
      // Extraire le texte des enfants pour générer l'ID

      const titleText = node.children
        .map((child) =>
          typeof child === "string" ? child : child.children || "idou",
        )
        .join("");

      node.attributes.id = generateIdFromTitle(titleText);
    }
  }

  // Parcourir les enfants récursivement
  if (node.children && Array.isArray(node.children)) {
    node.children.forEach(addIdsToHeadings);
  }
}
export function Markdown({ content, className }: Props) {
  addIdsToHeadings(content);

  return (
    <div
      className={cn(
        "prose prose-li:items-baseline prose-hr:my-8 prose-headings:font-medium prose-headings:mb-4 prose-headings:scroll-mt-20 prose-headings:text-start prose-headings:text-midnightblue prose-p:text-justify prose-p:text-midnightblue prose-li:my-1 prose-li:gap-2 marker:prose-ul:text-midnightblue prose-ul:pl-4 prose-a:text-teal prose-a:underline first:prose-p:mt-0 mx-auto mt-8 max-w-full",
        className,
      )}
    >
      {renderers.react(content, React, {
        components: {
          Heading: ({
            type,

            children,
          }: { children: string; type: string }) => {
            const id = generateIdFromTitle(children);
            const Tag = type as keyof JSX.IntrinsicElements;
            return <Tag id={id}>{children}</Tag>;
          },
          Image: ({ alt, source }: { source: string; alt: string }) => {
            return (
              <OptimizedImage
                className="rounded-clint my-0 border border-gray-200"
                source={source}
                alt={alt}
                imageProps={{
                  minHeight: "100%",
                  objectFit: "cover",
                }}
              />
            );
          },
          GoodcollectPhone: (props) => {
            const { phoneDisplay, phoneLink } = useEnv() || {};

            return (
              <>
                <a className={"text-secondary underline"} href={phoneLink}>
                  {phoneDisplay}
                </a>
                {props.children}
              </>
            );
          },
          // Offers: (props) => {
          //   return <div>Offers</div>;
          // },
        },
      })}
    </div>
  );
}
